import styledComponents from "styled-components";
import { styled } from "@material-ui/core/styles";
import ReactAvatarEditor from "react-avatar-editor";

const Div = styledComponents.div``;
const Label = styledComponents.label``;
const Figure = styledComponents.figure``;
const Input = styledComponents.input``;

export const AvatarEditorRange = styled(Input)(style => {
  return {
    width: "170px",
    margin: "0 auto"
  };
});

export const AvatarEditorImages = styled(Div)(style => {
  return {
    textAlign: "center"
  };
});

export const AvatarEditorImagesInner = styled(Div)(style => {
  return {
    textAlign: "center",
    width: "100%",
    maxWidth: "25rem",
    margin: "0 auto"
  };
});

export const AvatarEditor = styled(ReactAvatarEditor)(style => {
  return {
    width: "170px !important",
    height: "170px !important",
    margin: "0 auto",
    background: "#484848"
  };
});

export const AvatarImages = styled(Figure)(style => {
  return {
    width: "100%",
    maxWidth: "25rem",
    margin: "0 auto",
    "& > img": {
      width: "170px !important",
      height: "170px !important",
      margin: "0 auto",
      padding: "1rem;",
      boxSizing: "border-box",
      background: "#484848"
    }
  };
});

export const AvatarWrapper = styled(Div)(style => {
  return {
    margin: "0 0 20px",
    padding: "0",
    textAlign: "center"
  };
});

export const AvatarBtnsWrapper = styled(Div)(style => {
  return {
    margin: "10px 0 0",
    display: "flex",
    "@media (max-width: 520px)": {
      display: "block"
      // lineHeight: "inherit",
    }
  };
});

export const AvatarBtn = styled(Label)(style => {
  return {
    width: "100%",
    padding: "8px",
    boxSizing: "border-box",
    display: "block",
    color: "#000",
    background: "#EAEAEA",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
    "&:disabled": {
      opacity: 0.4,
      cursor: "not-allowed"
    },
    "&:hover": {
      opacity: 0.4,
      transition: "all .2s ease"
    },
    "&.upload": {
      background: "#afe170",
      color: "#333",
      "& > input": {
        display: "none"
      },
      "@media (max-width: 425px)": {
        height: "54px",
        lineHeight: "inherit"
      }
    },
    "&.save": {
      backgroundColor: "#afe170",
      color: "#fbfcfe",
      border: "none"
    },
    "@media (max-width: 425px)": {
      height: "54px",
      lineHeight: "36px"
    }
  };
});

export const AvatarBtnWrapper = styled(Div)(style => {
  return {
    width: "48%",
    marginRight: "2%",
    marginBottom: "5px",
    "&:last-child": {
      marginRight: 0
    },
    "@media (max-width: 520px)": {
      width: "100%"
      // lineHeight: "inherit",
    }
  };
});
