import {
  COUNTRIES_FETCH,
  COUNTRIES_FETCH_DONE
} from "../reducers/CountriesReducer";
import { rest } from "@karpeleslab/klbfw";
import { handleError } from "../../components/utils/apiErrorHandler";

export const fetchCountries = fetch => {
  return (dispatch, getState) => {
    if (getState().countries.countries.length > 0) return; // countries won't changes so we do not reload them
    dispatch({ type: COUNTRIES_FETCH });

    return rest("Country")
      .then(data => {
        dispatch({ type: COUNTRIES_FETCH_DONE, countries: data.data });
        return data.data;
      })
      .catch(err => {
        handleError(getState, dispatch, err);
      });
  };
};
