import React, { useCallback, useState } from "react";
import ReactPlayer from "react-player";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

// style
import style from "Scss/live.module.scss";

// img
import noSeat from "assets/img/no-seat-img.jpg"

const StreamContainer = ({ stream, streamHeight, slug, selected, ticketStatus }) => {
	const IMG_VARIATION = "strip&scale=470x600&format=jpeg";
  const [overLayShow, setOverLayShow] = useState(true);
  const { t } = useTranslation();

  const hideOverLay = useCallback(() => {
    setOverLayShow(false);
  }, [setOverLayShow]);

  const buildOverLay = useCallback(() => {
    if (overLayShow) {
      return (
        <div
          onClick={() => {
            hideOverLay();
          }}
          className={style["stream-overLay"]}
        >
          <span>{t("stream_mute_btn")}</span>
        </div>
      );
    } else {
      return null;
    }
  }, [overLayShow, hideOverLay, t]);

  const buildImg = useCallback((imgUrl) => {
    if (selected.Content_Cms_Entry_Data.Drive_Item) {
      return selected.Content_Cms_Entry_Data.Drive_Item.Media_Image[IMG_VARIATION]
    } else {
      return imgUrl
    }
  },[selected]);

  const buildReserveOpen = useCallback((imgUrl) => {
    if (!stream.Has_Seat) {
      return (
        <Link to={`/reserve/${slug}/1`}>
          <div
            className={style['stream']}
            style={{
              height: `${streamHeight}px`,
              backgroundImage: `url(${buildImg(imgUrl)})`,
              backgroundPosition: 'center center',
              backgroundRepeat: 'no-repeat',
              backgroundColor: "#0d0d0d",
              verticalAlign: "bottom",
              backgroundSize: "contain"
            }}
          >
            <p
              className={style['stream-text']}
            >この配信をご覧いただくには<br/>チケットの購入を行ってください。</p>
          </div>
        </Link>
      )
    }
    else {
      return (
        <div
          className={style['stream']}
          style={{
            height: `${streamHeight}px`,
            backgroundImage: `url(${buildImg(imgUrl)})`,
            backgroundPosition: 'center center',
            backgroundRepeat: 'no-repeat',
            backgroundColor: "#0d0d0d",
            verticalAlign: "bottom",
            backgroundSize: "contain"
          }}
        >
          <p
            className={style['stream-text']}
          >準備中です。</p>
        </div>
      )
    }
  },[buildImg, slug, stream.Has_Seat, streamHeight]);

  const buildBuyTicket = useCallback((imgUrl) => {
    switch (ticketStatus) {
      case 'past':
        return (
          <div
            className={style['stream']}
            style={{
              height: `${streamHeight}px`,
              backgroundImage: `url(${buildImg(imgUrl)})`,
              backgroundPosition: 'center center',
              backgroundRepeat: 'no-repeat',
              backgroundColor: "#0d0d0d",
              verticalAlign: "bottom",
              backgroundSize: "contain"
            }}
          >
            <p
              className={style['stream-text']}
            >開催済のライブ配信です。</p>
          </div>
				)
			case 'reserve_open':
        return buildReserveOpen()
			case 'reserve_closed':
        return (
          <div
            className={style['stream']}
            style={{
              height: `${streamHeight}px`,
              backgroundImage: `url(${buildImg(imgUrl)})`,
              backgroundPosition: 'center center',
              backgroundRepeat: 'no-repeat',
              backgroundColor: "#0d0d0d",
              verticalAlign: "bottom",
              backgroundSize: "contain"
            }}
          >
            <p
              className={style['stream-text']}
            >チケットの販売は終了しました。</p>
          </div>
        )
      default:
        return (
          <div
            className={style['stream']}
            style={{
              height: `${streamHeight}px`,
              backgroundImage: `url(${buildImg(imgUrl)})`,
              backgroundPosition: 'center center',
              backgroundRepeat: 'no-repeat',
              backgroundColor: "#0d0d0d",
              verticalAlign: "bottom",
              backgroundSize: "contain"
            }}
          >
            <p
              className={style['stream-text']}
            >準備中です。</p>
          </div>
				)
    }


  },[ticketStatus, buildImg, streamHeight, buildReserveOpen])


  const preparationLive = useCallback((imgUrl) => {
    if( imgUrl === noSeat) {
      return buildBuyTicket()
    }
    return (
      <div
        className={style['stream']}
        style={{
          height: `${streamHeight}px`,
          backgroundImage: `url(${buildImg(imgUrl)})`,
          backgroundPosition: 'center center',
          backgroundRepeat: 'no-repeat',
          backgroundColor: "#0d0d0d",
          verticalAlign: "bottom",
          backgroundSize: "contain"
        }}
      >
        <p
         className={style['stream-text']}
        >準備中です</p>
      </div>
    )
  },[streamHeight, buildBuyTicket, buildImg]); 

  const buildScreen = useCallback(() => {
    if (!stream.Has_Seat) {
      return preparationLive(noSeat)
    }
    else if (stream.Hls_Url === '') {
      return buildBuyTicket()
    }
    else {
      return (
        <div
          className={style['stream']}
          style={{
            height: `${streamHeight}px`
          }}
        >
          {buildOverLay()}
          <div
            className={style['stream-video']}
          >
            <ReactPlayer
              controls={true}
              url={stream.Hls_Url}
              width={"100%"}
              muted={overLayShow}
              playing={true}
              playsinline={true}
            />
          </div>
        </div>
      )
    }
  }, [stream, buildOverLay, overLayShow, streamHeight, preparationLive, buildBuyTicket])


  // ストリーム情報がない場合
  if (!stream) return null;

  return (
    <>
      {buildScreen()}
    </>
  )

};

export default StreamContainer;
