import React from "react";
//import { Get } from "@karpeleslab/klbfw";
import { useTranslation } from "react-i18next";

const Text = () => {
  const { t } = useTranslation();

  return <textarea id="mail-text" defaultValue={t("mail_order_desc_txt")} />;
};

export default Text;
