import { primaryColor } from "assets/jss/material-kit-react.js";

const eventStyle = {
  titleEvent: {
    color: primaryColor,
    fontWeight: "bold",
    fontSize: "32px",
    textAlign: "center",
    margin: "0"
  },
  titleShort: {
    color: primaryColor,
    fontSize: "16px",
    textAlign: "center",
    margin: "0"
  },
  titleGroup: {
    margin: "0 0 12px"
  },
  subtitleEvent: {
    color: primaryColor,
    fontWeight: "bold",
    textTransform: "uppercase",
    fontSize: "12px",
    cursor: "pointer",
    textAlign: "center"
  },
  ticketReserve: {
    background: "white",
    border: "solid 1px #05d1d6",
    margin: "12px 0"
  },
  ticketReserved: {
    background: "white",
    border: "solid 1px #05d1d6",
    margin: "18px 0"
  },
  price: {
    textAlign: "right"
  },
  date: {
    fontWeight: "bold",
    textTransform: "uppercase"
  },
  root: {
    marginBottom: "12px"
  }
};

export default eventStyle;
