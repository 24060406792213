import React, { useEffect, useState, useRef, useCallback } from "react";
import { connect } from "react-redux";
import { fetchEvent } from "store/actions/ScheduleAction";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { rest } from "@karpeleslab/klbfw";
import moment from "moment";
import "moment/locale/ja";
import classNames from "classnames";
import { useWindowSize } from '@react-hook/window-size';

// component
import Helmet from "components/instances/instance/Helmet/Helmet";
import Loading from "components/common/feedback/loading/Loading";
import Stream from "views/Live/Stream";
import Chat from "views/Live/Chat";
import LiveInfo from "views/Live/Info"

// style
import style from "Scss/live.module.scss";

// constant
const IMG_VARIATION = "strip&scale=470x600&format=jpeg";

const LiveDetail = ({ fetch, selected, user }) => {
  const { t } = useTranslation();
  const { slug } = useParams();
  const [loading, setLoading] = useState(true);
  const [streamInfo, setStreamInfo] = useState();
  const [chatStatus, setChatStatus] = useState(true);
  const [streamHeight, setStreamHeight] = useState();
  moment.locale("ja");
  const streamRef = useRef(null);
  const [windowWidth] = useWindowSize();
  const [scrollLock, setScrolleLock] = useState('false');
  const [useChat, setUseChat] = useState({
    type: null,
    boolean: true
  });
  const [disabledChat, setDisabledChat] = useState(false);

  useEffect(() => {
    fetch(slug).then(v => setLoading(false));
  }, [fetch, slug]);

  useEffect(() => {

    if ( streamInfo !== void 0 && selected !== null ) {
      if ( streamInfo.Hls_Url === "" &&  selected.Planner_Event.Status === 'past') {
        setDisabledChat(true);
      }
      else {
        setDisabledChat(false);
      }
    }

  }, [selected, streamInfo])


  const checkTime = () => {
    const currentDate = moment();
    const startDate = moment(parseInt(selected.Planner_Event.Event_Date.unixms)).add(-15, 'minutes');
    const endDate = moment(parseInt(selected.Planner_Event.Event_Date.unixms)).add(4, 'hours');

    if (moment(startDate).isSameOrBefore(moment(currentDate)) && moment(endDate).isSameOrBefore(moment(currentDate))) {
      setUseChat({
        type: 'after',
        boolean: false,
      })

    } else if (!moment(startDate).isSameOrBefore(moment(currentDate)) && !moment(endDate).isSameOrBefore(moment(currentDate))) {
      setUseChat({
        type: 'before',
        boolean: false,
      })
    } else {
      setUseChat({
        type: null,
        boolean: true,
      })
    }
  }


  const updatePlanner = (state) => {
    if (selected === null || selected === void 0) return false;
    
    rest("Planner/Event/" + selected.Planner_Event__ + ":stream")
      .then(res => {
        if (!state.enabled) return;

        setStreamInfo(res.data);

        // 15分前表示の処理
        checkTime()
        // setUseChat({
        //   type: null,
        //   boolean: true
        // })

        let t = res.data.Hls_Recheck_Delay;
        if (t < 5) t = 5;
        state.timeout = setTimeout(() => updatePlanner(state), t * 1000);
      })
      .catch(() => {
        if (!state.enabled) return;
        state.timeout = setTimeout(() => updatePlanner(state), 60000);
      });
  };

  useEffect(() => {
    let state = { enabled: true };
    updatePlanner(state);

    return () => {
      state.enabled = false;
      clearTimeout(state.timeout);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

	// load 
	useEffect(() => {

		if (selected !== null && streamInfo !== void 0 && user.user !== null) {
      setLoading(false);
		} else {
			setLoading(true);
    }
    
  }, [selected, streamInfo, user]);
  

  const streamGcd = useCallback(() => {
    const { width } = streamRef.current.getBoundingClientRect();
    const x = width * 9 / 16;
    setStreamHeight(x)
  }, [streamRef])

	useEffect(() => {
    if (streamRef.current !== null) {
      streamGcd();
    }
  }, [streamRef, loading, streamGcd, windowWidth, chatStatus]);


	useEffect(() => {
    if (windowWidth <= 825) {
      setChatStatus(false)
    }
    else {
      setChatStatus(true)
    }
  }, [windowWidth]);


  useEffect(() => {

    if (windowWidth <= 825) {
      if (chatStatus === true) {
        setScrolleLock(true)
      } else {
        setScrolleLock(false)
      }
    } else {
      setScrolleLock(false)
    }

  }, [windowWidth, chatStatus])


	if (loading) {
		return (
			<div
				className={style['live-loading-wrapper']}
			>
				<Loading
					className={style['live-loading']}
					size={60}
				/>
			</div>
		)
	} 

  return (
    <>
      <Helmet title={t('helmet_live')} />
      <div
        className={style['live-head-attention']}
      >
        チャットにはユーザーネームが表示されます。実名の利用は控えましょう。
      </div>
      <div
        className={classNames(style['live-head-wrapper'], {
          [style['live-head-wrapper-chat']]: chatStatus,
          [style['live-head-wrapper-chat']]: !disabledChat,
        })}
      >
        <div
          className={style['live-head-stream']}
          ref={streamRef}
        >
          <Stream
            stream={streamInfo}
            streamHeight={streamHeight}
            slug={slug}
            selected={selected}
            ticketStatus={selected.Planner_Event.Status}
          />
        </div>
        { !disabledChat && 
          <div
            className={style['live-head-chat']}
          >
            <Chat
              user={user.user}
              useChat={useChat}
              chatStatus={chatStatus}
              setChatStatus={setChatStatus}
              streamHeight={streamHeight}
              Planner_Event__={selected.Planner_Event__}
              stream={streamInfo}
              slug={slug}
              ticketStatus={selected.Planner_Event.Status}
              scrollLock={scrollLock}
            />
          </div>
        }
      </div>
      <div
        className={style['live-info-wrapper']}
      >
        <div
          className={style['live-info']}
        >
          <LiveInfo
            selected={selected}
            chatStatus={chatStatus}
            setChatStatus={setChatStatus}
            disabledChat={disabledChat}
          />
        </div>
      </div>
    </>
  );
};

const mapStateToProps = state => {
  return {
    user: state.user,
    selected: state.schedule.selected,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetch: slug =>
      dispatch(fetchEvent(slug, { image_variation: IMG_VARIATION }))
  };
};

LiveDetail.fetchEvent = (match, store) =>
  store.dispatch(fetchEvent(match.params.slug));

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LiveDetail);
