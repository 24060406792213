import React, { Fragment } from "react";
import Html from "./Html";
import Text from "./Text";
import Helmet from "react-helmet";
//import {Get} from "@karpeleslab/klbfw";
import { useTranslation } from "react-i18next";

const Mail = () => {
  const { t } = useTranslation();
  return (
    <Fragment>
      <Helmet>
        <title>{t("title_store_mail_subject")} </title>
        <meta
          name="mail-from-name"
          content={"Echelle - " + t("order_payment_confirmation")}
        />
        <meta name="mail-from" content="info@echelle.co.jp" />
      </Helmet>
      <Html />
      <Text />
    </Fragment>
  );
};

export default Mail;
