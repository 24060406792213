import ForgottenPasswordMail from "../mails/Password/Forgotten/Mail";
import RecoverPasswordMail from "../mails/Password/Recover/Mail";
import WaitingList from "../mails/Account/WaitingList/Mail";
import WaitingListAccepted from "../mails/Account/WaitingListAccepted/Mail";
import BillingFailed from "../mails/Order/Billing/Failed/Mail";
import BillingInvalidate from "../mails/Order/Billing/Invalidate/Mail";
import NewOrder from "../mails/Order/Created/Mail";
import BillPdf from "../mails/Order/Billing/Pdf/Pdf";
import ReserveLiveConfirm from "../mails/ReserveLiveConfirm/Mail";
import AccountConfirmMail from "../mails/Account/Confirm/Mail";

export default [
  {
    path: "/mail/mail_forgotten_pwd.mail",
    component: ForgottenPasswordMail,
    type: "route",
    mode: "ssr-mail"
  },
  {
    path: "/mail/account_confirm.mail",
    component: AccountConfirmMail,
    type: "route",
    mode: "ssr-mail"
  },
  {
    path: "/mail/reserve_live_paid.mail",
    component: ReserveLiveConfirm,
    type: "route",
    mode: "ssr-mail"
  },
  {
    path: "/mail/reserve_live_confirm.mail",
    component: ReserveLiveConfirm,
    type: "route",
    mode: "ssr-mail"
  },
  {
    path: "/mail/mail_pwd_recovered.mail",
    component: RecoverPasswordMail,
    type: "route",
    mode: "ssr-mail"
  },
  {
    path: "/mail/registered_waiting_list.mail",
    component: WaitingList,
    type: "route",
    mode: "ssr-mail"
  },
  {
    path: "/mail/registered_waiting_list_ready.mail",
    component: WaitingListAccepted,
    type: "route",
    mode: "ssr-mail"
  },
  {
    path: "/mail/order/billing_failed.mail",
    component: BillingFailed,
    type: "route",
    mode: "ssr-mail"
  },
  {
    path: "/mail/order/billing_method_invalidate.mail",
    component: BillingInvalidate,
    type: "route",
    mode: "ssr-mail"
  },
  {
    path: "/mail/order/order.mail",
    component: NewOrder,
    type: "route",
    mode: "ssr-mail"
  },
  {
    path: "/mail/order/bill.pdf",
    component: BillPdf,
    type: "route",
    mode: ["ssr-open", "client-open"]
  }
];
