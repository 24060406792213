import React from "react";

// style
import styles from "Scss/loading.module.scss";

// icon
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

const Loading = () => {
  return (
    <div className={styles["loading"]}>
      <FontAwesomeIcon icon={faSpinner} />
    </div>
  );
};

export default Loading;
