import { rest } from "@karpeleslab/klbfw";
import { handleError } from "../../components/utils/apiErrorHandler";

import {
  FETCH_ORDER_HISTORY_BEGIN,
  FETCH_ORDER_HISTORY_SUCCESS,
  HISTORY_SET_PAGING
} from "../reducers/OrderHistoryReducer";

export const fetchOrderHistory = () => {
  return (dispatch, getState) => {
    dispatch({ type: FETCH_ORDER_HISTORY_BEGIN });

    const params = {
      ...getState().orderHistory.historyPaging,
      sort: {
        Date: "ASC"
      }
    };

    return rest("Order", "GET", params)
      .then(m => {
        dispatch({
          type: FETCH_ORDER_HISTORY_SUCCESS,
          data: m.data,
          paging: m.paging
        });
        return m.data;
      })
      .catch(err => {
        handleError(getState, dispatch, err);
      });
  };
};

export const setOrderHistoryPaging = newPaging => {
  return (dispatch, getState) => {
    dispatch({
      type: HISTORY_SET_PAGING,
      paging: { ...getState().orderHistory.historyPaging, ...newPaging }
    });
  };
};
