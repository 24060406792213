import React, { useEffect } from "react";
import { connect } from "react-redux";
import Helmet from "components/instances/instance/Helmet/Helmet";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
// @material-ui/icons
import PersonIcon from "@material-ui/icons/Person";
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
import LockIcon from "@material-ui/icons/Lock";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import { useLocation } from "react-router-dom";

import styles from "assets/jss/material-kit-react/views/profilePage.js";

import Account from "./Sections/Account";
import Information from "./Sections/Information";
import Security from "./Sections/Security";
import PurchasedTickets from "./Sections/PurchasedTickets";

import DefaultAvatar from "assets/img/avatar.png";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Panel from "../../components/common/data/tab/Panel";
import makeStyles from "@material-ui/core/styles/makeStyles";
import useTheme from "@material-ui/core/styles/useTheme";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import { useTranslation } from "react-i18next";

const useStyles = makeStyles(styles);

const ProfilePage = ({ user }) => {
  const classes = useStyles();
  const imageClasses = classNames(
    classes.imgRaised,
    classes.imgRoundedCircle,
    classes.imgFluid
  );
  const location = useLocation();

  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { t } = useTranslation();

  const [tabIndex, setTabIndex] = React.useState(0);

  const initialAvatar = user.Profile.Media_Image
    ? user.Profile.Media_Image.Url
    : DefaultAvatar;

  const a11yProps = index => {
    return {
      id: `scrollable-force-tab-${index}`,
      "aria-controls": `scrollable-force-tabpanel-${index}`
    };
  };

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  useEffect(() => {
    const hash = location.hash;

    switch(hash){
      case '#purchased':
        setTabIndex(0);
        break;
      case '#account':
        setTabIndex(1);
        break;
      case '#information':
        setTabIndex(2);
        break;
      case '#security':
        setTabIndex(3);
        break;
      default:
        setTabIndex(0);
    }

  }, [location])


  return (
    <div>
      <Helmet title={t("helmet_echelle_account")} />
      <div className={classes.container}>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={6}>
            <div className={classes.profile}>
              <div>
                <img
                  src={initialAvatar}
                  alt="..."
                  className={imageClasses}
                  style={{ paddingTop: "80px" }}
                />
              </div>
              <div className={classes.name}>
                <h3 className={classes.title}>
                  {user.Profile.Display_Name
                    ? user.Profile.Display_Name
                    : user.Email}
                </h3>
              </div>
            </div>
          </GridItem>
        </GridContainer>

        <GridContainer justify="center">
          <GridItem xs={12}>
            <Tabs
              centered={!mobile}
              value={tabIndex}
              onChange={handleTabChange}
              variant={mobile ? "scrollable" : "standard"}
              scrollButtons="on"
              indicatorColor="primary"
              textColor="primary"
            >
              <Tab
                label={t("account_echelle_information_tab")}
                icon={<PersonIcon />}
                {...a11yProps(0)}
              />
              <Tab
                label={t("account_user_information_tab")}
                icon={<PersonIcon />}
                {...a11yProps(1)}
              />
              <Tab
                label={t("account_profile_tab")}
                icon={<AssignmentIndIcon />}
                {...a11yProps(2)}
              />
              <Tab
                label={t("account_password_tab")}
                icon={<LockIcon />}
                {...a11yProps(3)}
              />
            </Tabs>
          </GridItem>

          <GridItem xs={12}>
            <Panel index={0} value={tabIndex}>
              <PurchasedTickets />
            </Panel>
            <Panel index={1} value={tabIndex}>
              <Account />
            </Panel>
            <Panel index={2} value={tabIndex}>
              <Information />
            </Panel>
            <Panel index={3} value={tabIndex}>
              <Security />
            </Panel>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    user: state.user.user
  };
};

const mapDispatchToProps = dispatch => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProfilePage);
