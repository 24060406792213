const initState = {
  loading: false,
  faq: [],
  tags: []
};

export const FAQ_FETCH = "FAQ_FETCH";
export const FAQ_FETCH_DONE = "FAQ_FETCH_DONE";

export const TAGS_FETCH = "TAGS_FETCH";
export const TAGS_FETCH_DONE = "TAGS_FETCH_DONE";

const faqReducer = (state = initState, action) => {
  switch (action.type) {
    case FAQ_FETCH:
      return { ...state, loading: true };
    case FAQ_FETCH_DONE:
      return { ...state, loading: false, faq: action.faq };
    case TAGS_FETCH:
      return { ...state, loading: true };
    case TAGS_FETCH_DONE:
      return { ...state, loading: false, tags: action.tags };
    default:
      return state;
  }
};

export default faqReducer;
