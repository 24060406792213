import Home from "views/LandingPage/LandingPage";

import Login from "views/LoginPage/LoginPage";
import Account from "views/AccountPage/AccountPage";
import OrderHistory from "views/OrderHistoryPage/OrderHistoryPage";
import { updateUser } from "store/actions/UserActions";
import mailRoutes from "./mailRoutes";
import EventDetails from "views/Event/Sections/Event";
import Reserve from "views/Reserve/Reserve";
import EventList from "views/EventList/EventListPage";
import PasswordForgotten from "views/PasswordForgotten/PasswordForgotten";
import PasswordReset from "views/PasswordReset/PasswordReset";
import Order from "views/order/order";

// Live
import LiveDetail from "views/Live/Live";

// This set of promises will waited prior running all loadData promises.
export const prerequisites = store => {
  return [
    store.dispatch(updateUser()).catch(() => {}) // ignore error
  ];
};

export default [
  {
    path: "/",
    component: Home,
    exact: true,
    type: "route"
  },
  {
    path: "/login",
    component: Login,
    exact: true,
    type: "route"
  },
  {
    path: "/events/:year/:month",
    component: EventList,
    exact: true,
    type: "route",
    loadData: [EventList.fetchSchedule]
  },
  {
    path: "/event/:slug",
    component: EventDetails,
    loadData: [EventDetails.fetchEvent],
    type: "route"
  },
  {
    path: "/reserve/:event/:qty",
    component: Reserve,
    exact: true,
    type: "route"
  },
  {
    path: "/live/:slug",
    component: LiveDetail,
    loadData: [LiveDetail.fetchEvent],
    type: "route"
  },
  {
    path: "/account",
    component: Account,
    exact: true,
    type: "route"
  },
  {
    path: "/order/:order",
    component: Order,
    exact: true,
    type: "route",
    loginRequired: true
  },
  {
    path: "/order_history",
    component: OrderHistory,
    exact: true,
    type: "route"
  },
  {
    path: "/forget-password",
    component: PasswordForgotten,
    exact: true,
    type: "route"
  },
  {
    path: "/reset-password",
    component: PasswordReset,
    exact: true,
    type: "route"
  },
  ...mailRoutes
];
