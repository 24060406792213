import React, { useEffect, useState } from "react";
import AvatarEditor from "components/avatar-editor/AvatarEditor";
import Button from "@material-ui/core/Button";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { updateAvatar } from "store/actions/UserActions";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";

const Picture = ({ user, changingAvatar, updateAvatar }) => {
  const { t } = useTranslation();
  const [avatarSaveBtnEnabled, setAvatarSaveBtnEnabled] = useState(false);

  const initialAvatar = user.Profile.Media_Image
    ? user.Profile.Media_Image.Url
    : null;
  const [avatarImage, setAvatarImage] = useState(initialAvatar);

  useEffect(() => {
    if (initialAvatar !== avatarImage) {
      setAvatarSaveBtnEnabled(true);
      return;
    }
    setAvatarSaveBtnEnabled(false);
  }, [avatarImage, initialAvatar]);

  const handleSave = e => {
    if (!avatarImage || avatarImage === initialAvatar) return;
    updateAvatar(avatarImage);
  };

  return (
    <GridContainer
      spacing={3}
      direction="row"
      justify="center"
      alignItems="center"
    >
      <GridItem xs={10} md={9}>
        <AvatarEditor
          defaultAvatar={initialAvatar}
          field="profile"
          setAvatarImage={(n, v) => setAvatarImage(v)}
          fieldId="superId"
        />
      </GridItem>
      <GridItem xs={10} md={4}>
        <Button
          fullWidth
          color="primary"
          variant="outlined"
          disabled={!avatarSaveBtnEnabled || changingAvatar}
          onClick={handleSave}
        >
          {t("save_btn")}
        </Button>
      </GridItem>
    </GridContainer>
  );
};

const mapStateToProps = state => {
  return {
    user: state.user.user,
    changingAvatar: state.user.changingAvatar
  };
};

const mapDispatchToProps = dispatch => {
  return {
    // Meh refactor this stuff
    updateAvatar: file =>
      dispatch(
        updateAvatar([
          {
            file: file,
            target: "User/@/Profile:addImage",
            param: { purpose: "main" }
          }
        ])
      )
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Picture);
