import React from "react";
import TableRow from "@material-ui/core/TableRow";
import Skeleton from "@material-ui/lab/Skeleton";
import TableCell from "@material-ui/core/TableCell";
import { connect } from "react-redux";
import moment from "moment";
import { useTranslation } from "react-i18next";

const Row = ({ reserved, loading }) => {
  const displayUser = !loading && reserved;
  const { t } = useTranslation();

  return (
    <TableRow>
      <TableCell>
        {!displayUser && <Skeleton />}
        {displayUser && (
          <span>{moment(parseInt(reserved.Date.unixms)).format("LLL")}</span>
        )}
      </TableCell>
      <TableCell>
        {!displayUser && <Skeleton />}
        {displayUser && <span>{reserved.Planner_Event.Name}</span>}
      </TableCell>
      <TableCell>
        {!displayUser && <Skeleton />}
        {displayUser && (
          <span>
            {reserved.Planner_Event &&
              moment(parseInt(reserved.Planner_Event.Event_Date.unixms)).format(
                "LLL"
              )}
          </span>
        )}
      </TableCell>
      <TableCell>
        {!displayUser && <Skeleton />}
        {displayUser && <span>{reserved.Planner_Event.Location}</span>}
      </TableCell>
      <TableCell>
        {!displayUser && <Skeleton />}
        {displayUser && <span>{t(`status_${reserved.Status ?? "-"}`)}</span>}
      </TableCell>
      <TableCell>
        {!displayUser && <Skeleton />}
        {displayUser && (
          <span>{reserved.Planner_Event_Seat.Price.display ?? "-"}</span>
        )}
      </TableCell>
    </TableRow>
  );
};

const mapStateToProps = (state, { loading, idx }) => {
  if (loading) return {};
  return {
    reserved: state.reserve.allReservedSeats[idx]
  };
};

export default connect(
  mapStateToProps,
  null
)(Row);
