import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
import TextField from "@material-ui/core/TextField";
import BoxedText from "../../components/common/feedback/BoxedText";
import { resetPassword } from "../../store/actions/UserActions";
import Button from "../../components/CustomButtons/Button";

const Form = ({ resetPassword, login, resetKey, passwordReseting }) => {
  const { t } = useTranslation();
  const [reseted, setReseted] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordRepeat, setNewPasswordRepeat] = useState("");
  const [sendEnabled, setSendEnabled] = useState(false);

  useEffect(() => {
    setSendEnabled(
      newPasswordRepeat.trim() !== "" &&
        newPassword.trim() !== "" &&
        newPassword === newPasswordRepeat
    );
  }, [newPassword, newPasswordRepeat, setSendEnabled]);

  const handleSend = () => {
    resetPassword(newPassword, newPasswordRepeat, login, resetKey).then(e =>
      setReseted(true)
    );
  };

  if (reseted) {
    return (
      <BoxedText
        disabledGutter
        title={t("reset_password_done_title")}
        description={t("reset_password_done_desc")}
      />
    );
  }

  return (
    <GridContainer
      spacing={3}
      direction="column"
      alignItems="center"
      justify="center"
    >
      <GridItem xs={12} md={6}>
        <TextField
          label={t("label_new_password")}
          fullWidth
          value={newPassword}
          onChange={e => setNewPassword(e.target.value)}
          helperText={t("reset_password_new_password_helperText")}
          variant="outlined"
          type="password"
          disabled={passwordReseting}
        />
      </GridItem>
      <GridItem xs={12} md={6}>
        <TextField
          label={t("label_new_password_repeat")}
          fullWidth
          value={newPasswordRepeat}
          onChange={e => setNewPasswordRepeat(e.target.value)}
          variant="outlined"
          type="password"
          error={newPasswordRepeat !== newPassword}
          helperText={
            newPasswordRepeat !== newPassword
              ? t("confirm_password_not_match")
              : ""
          }
          disabled={passwordReseting}
        />
      </GridItem>
      <GridItem xs={12} md={4}>
        <Button
          variant="contained"
          color="primary"
          disabled={!sendEnabled || passwordReseting}
          fullWidth
          onClick={handleSend}
        >
          {t("reset_password_send_btn")}
        </Button>
      </GridItem>
    </GridContainer>
  );
};

const mapStateToProps = state => {
  return {
    passwordReseting: state.user.passwordReseting
  };
};

const mapDispatchToProps = dispatch => {
  return {
    resetPassword: (p1, p2, login, key) =>
      dispatch(resetPassword(p1, p2, login, key))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form);
