import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Grid, TextField } from "@material-ui/core";
import Button from "components/CustomButtons/Button.js";
import { connect } from "react-redux";
import { updateProfile } from "store/actions/UserActions";
import MomentUtils from "@date-io/moment";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import moment from "moment";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";

const Information = ({ user, changingInfo, updateProfile }) => {
  const { t } = useTranslation();
  const [saveEnabled, setSaveEnabled] = useState(false);
  const [username, setUsername] = useState(user.Profile.Display_Name);
  const originalBirthdate =
    user.Profile.Birthdate && user.Profile.Birthdate !== "0000-00-00"
      ? moment(user.Profile.Birthdate)
      : null;
  const [birthdate, setBirthdate] = useState(originalBirthdate);

  const originalGender = user.Profile.Gender ?? "N/A";
  const [gender, setGender] = useState(originalGender);

  const genderLabel = React.useRef(null);
  const [genderLabelWidth, setGenderLabelWidth] = useState(0);

  useEffect(() => {
    setGenderLabelWidth(genderLabel.current.offsetWidth);
  }, []);

  useEffect(() => {
    if (username !== user.Profile.Display_Name && username.length !== 0) {
      setSaveEnabled(true);
      return;
    }

    if (!birthdate && originalBirthdate) {
      setSaveEnabled(true);
      return;
    }

    if (birthdate && !originalBirthdate) {
      setSaveEnabled(true);
      return;
    }

    if (
      birthdate &&
      originalBirthdate &&
      birthdate.format("YYYY-MM-DD") !== originalBirthdate.format("YYYY-MM-DD")
    ) {
      setSaveEnabled(true);
      return;
    }

    if (gender !== originalGender) {
      setSaveEnabled(true);
      return;
    }

    setSaveEnabled(false);
  }, [username, birthdate, user, gender, originalBirthdate, originalGender]);

  const handleSave = () => {
    let p = {
      Gender: gender === "N/A" ? null : gender,
      Display_Name: username
    };

    if (birthdate) {
      p.Birthdate = birthdate.format("YYYY-MM-DD");
    }

    updateProfile(p);
  };

  return (
    <Grid container spacing={3} alignItems="center">
      <Grid item xs={12}>
        <Grid
          container
          spacing={3}
          direction="row"
          justify="center"
          alignItems="center"
        >
          <Grid item xs={10} md={9}>
            <TextField
              variant="outlined"
              fullWidth
              label={t("profile_username")}
              value={username}
              disabled={changingInfo}
              onChange={e => setUsername(e.target.value)}
            />
          </Grid>
          <Grid item xs={10} md={9}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <DatePicker
                label={t("profile_birthdate")}
                inputVariant="outlined"
                value={birthdate}
                disabled={changingInfo}
                onChange={setBirthdate}
                fullWidth
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={10} md={9}>
            <FormControl variant="outlined" fullWidth disabled={changingInfo}>
              <InputLabel ref={genderLabel} htmlFor="gender-select">
                {t("profile_gender")}
              </InputLabel>
              <Select
                native
                value={gender}
                onChange={e => setGender(e.target.value)}
                labelWidth={genderLabelWidth}
                inputProps={{
                  id: "gender-select"
                }}
              >
                <option value="N/A">{t("gender_not_defined")}</option>
                <option value="M">{t("gender_male")}</option>
                <option value="F">{t("gender_female")}</option>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={10} md={9}>
            <Grid container justify="flex-end">
              <Button
                color="primary"
                variant="contained"
                disabled={!saveEnabled || changingInfo}
                onClick={handleSave}
              >
                {t("save_btn")}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = state => {
  return {
    user: state.user.user,
    changingInfo: state.user.changingInfo
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateProfile: data => dispatch(updateProfile(data))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Information);
