const initState = {
  data: [],
  error: null,
  selected: null,
  eventsPagingCount: 0,
  eventsPaging: {
    page_no: 1,
    results_per_page: 20
  }
};

export const FETCH_SCHEDULE_BEGIN = "FETCH_SCHEDULE_BEGIN";
export const FETCH_SCHEDULE_SUCCESS = "FETCH_SCHEDULE_SUCCESS";

export const FETCH_FUTURE_SCHEDULE_BEGIN = "FETCH_FUTURE_SCHEDULE_BEGIN";
export const FETCH_FUTURE_SCHEDULE_SUCCESS = "FETCH_FUTURE_SCHEDULE_SUCCESS";

export const EVENT_FETCH = "EVENT_FETCH";
export const EVENT_FETCH_DONE = "EVENT_FETCH_DONE";

export const EVENTS_SET_PAGING = "EVENTS_SET_PAGING";

const scheduleReducer = (state = initState, action) => {
  switch (action.type) {
    case FETCH_SCHEDULE_BEGIN:
      // Mark the state as "loading" so we can show a spinner or something
      // Also, reset any errors. We're starting fresh.
      return {
        ...state,
        loading: true,
        error: null
      };

    case FETCH_SCHEDULE_SUCCESS:
      // All done: set loading "false".
      // Also, replace the items with the ones from the server
      return {
        ...state,
        loading: false,
        data: action.schedule,
        eventsPagingCount: action.paging.count
      };

    // future schedule actions
    case FETCH_FUTURE_SCHEDULE_BEGIN:
      return { ...state, loading: true, error: null };
    case FETCH_FUTURE_SCHEDULE_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.schedule,
        eventsPagingCount: action.paging.count
      };

    case EVENT_FETCH:
      // Mark the state as "loading" so we can show a spinner or something
      // Also, reset any errors. We're starting fresh.
      return {
        ...state,
        loading: true,
        error: null
      };
    case EVENT_FETCH_DONE:
      // Mark the state as "loading" so we can show a spinner or something
      // Also, reset any errors. We're starting fresh.
      return {
        ...state,
        loading: false,
        error: null,
        selected: action.event
      };
    case EVENTS_SET_PAGING:
      return { ...state, eventsPaging: action.paging };
    default:
      return state;
  }
};

export default scheduleReducer;
