import React from "react";
import { Get } from "@karpeleslab/klbfw";
import moment from "moment";
import { useTranslation } from "react-i18next";

const Html = () => {
  const { t } = useTranslation();

  const Payment_Class = () => {
    if (Get("_order").Payment_Class !== "") {
      return (
        <tr>
          <td width="20%" nowrap>
            {t("payment_method")}
          </td>
          <td>: {Get("_order").Payment_Method}</td>
        </tr>
      );
    }
  };

  const Shipping_User_Location = () => {
    if (Get("_order").Shipping_User_Location) {
      return (
        <td valign="top">
          <h3>
            <font color="#33212f">{t("shipping_address")}</font>
          </h3>
          <p>
            {Get("_order").Shipping_User_Location.Display[0]}{" "}
            {Get("_order").Shipping_User_Location.Display[1]}
          </p>
        </td>
      );
    } else {
      return (
        <td valign="top">
          <h3>
            <font color="#33212f">{t("shipping_address")}</font>
          </h3>
          <p>
            {Get("_order").Billing_User_Location.Display[0]}{" "}
            {Get("_order").Billing_User_Location.Display[1]}
          </p>
        </td>
      );
    }
  };

  const quantity = line => {
    if (line.Quantity) {
      return line.Quantity;
    } else {
      return "1";
    }
  };

  const items = () => {
    return Get("_order").Items.map(line => {
      return (
        <tr>
          <td className="prod_name">{line.Catalog_Product["Basic.Name"]}</td>
          <td className="unit_price" align="right">
            {line.Price.display}
          </td>
          <td className="quantity" align="center">
            {quantity(line)}
          </td>
          <td className="prod_total" align="right">
            {line.Full_Price.display}
          </td>
        </tr>
      );
    });
  };

  return (
    <div id="mail-html">
      <div
        style={{
          color: "#666",
          marginTop: "-6px"
        }}
      >
        <div style={{ width: "600px", textAlign: "center", margin: "auto" }}>
          <div style={{ textAlign: "center" }}>
            <img
              src="https://live.echelle.co.jp/img/header_mailing.png"
              width="134px"
              alt={t("site_name")}
            />
          </div>
        </div>

        <table
          cellSpacing="0"
          cellPadding="5"
          border="0"
          align="center"
          width="600"
          style={{
            lineHeight: "1.4rem",
            color: "#989d9f"
          }}
        >
          <tr>
            <td>
              <hr size="2" color="#B0E170" />
            </td>
          </tr>
          <tr>
            <td>
              {Get("_order").Billing_User_Location.Last_Name}{" "}
              {Get("_order").Billing_User_Location.First_Name}
              <br />
              <br />
              {t("order_thanks")}
            </td>
          </tr>
          <tr>
            <td>
              <table
                cellSpacing="0"
                cellPadding="10"
                border="1"
                bordercolor="#ffffff"
                align="center"
                width="100%"
                style={{
                  borderCollapse: "collapse",
                  lineHeight: "1.4rem",
                  border: "1px solid #ffffff"
                }}
              >
                <tr>
                  <td
                    bgcolor="#B0E170"
                    style={{ color: "#FFFFFF", fontWeight: 500 }}
                  >
                    <b style={{ color: "#FFFFFF" }}>{t("order_number")}</b> #
                    {Get("_order").Invoice_Number}
                    <br />
                  </td>
                </tr>
              </table>
            </td>
          </tr>
          <tr>
            <td>
              <table
                cellSpacing="0"
                cellPadding="0"
                border="0"
                align="center"
                width="100%"
                style={{ lineHeight: "1.4rem" }}
              >
                <tr>
                  <td width="20%" nowrap>
                    {t("order_date")}
                  </td>
                  <td>
                    :{" "}
                    {moment(Get("_order").Created.unix * 1000).format(
                      "YYYY-MM-DD HH:mm:ss"
                    )}
                  </td>
                </tr>
                {Get("_order").Invoice_Date && (
                  <tr>
                    <td width="20%" nowrap>
                      {t("payment_date")}
                    </td>
                    <td>
                      :{" "}
                      {moment(Get("_order").Invoice_Date.unix * 1000).format(
                        "YYYY-MM-DD HH:mm:ss"
                      )}
                    </td>
                  </tr>
                )}
                {Payment_Class()}
              </table>
            </td>
          </tr>
          <tr>
            <td>
              <hr size="2" color="#B0E170" />
            </td>
          </tr>
          <tr>
            <td>
              <table
                cellSpacing="0"
                cellPadding="5"
                border="0"
                align="center"
                width="100%"
                style={{
                  lineHeight: "1.4rem"
                }}
              >
                <tr>
                  <td valign="top">
                    <h3>
                      <font color="#33212f">{t("invoice")}</font>
                    </h3>
                    <p>
                      {Get("_order").Billing_User_Location.Display[0]}{" "}
                      {Get("_order").Billing_User_Location.Display[1]}
                    </p>
                  </td>
                  {Shipping_User_Location()}
                </tr>
              </table>
            </td>
          </tr>
          <tr>
            <td>
              <hr size="2" color="#B0E170" />
            </td>
          </tr>
          <tr>
            <td>
              <h3>
                <font color="#33212f">{t("order_details")}</font>
              </h3>
              <table
                cellSpacing="0"
                cellPadding="5"
                border="1"
                bordercolor="#ffffff"
                align="center"
                width="100%"
                style={{
                  borderCollapse: "collapse",
                  lineHeight: "1.4rem",
                  border: "1px solid #fff"
                }}
              >
                <tr>
                  <th
                    bgcolor="#B0E170"
                    style={{ color: "#FFFFFF", fontWeight: 500 }}
                  >
                    {t("product_name")}
                  </th>
                  <th
                    bgcolor="#B0E170"
                    style={{ color: "#FFFFFF", fontWeight: 500 }}
                  >
                    {t("price")}
                  </th>
                  <th
                    bgcolor="#B0E170"
                    style={{ color: "#FFFFFF", fontWeight: 500 }}
                  >
                    {t("quantity")}
                  </th>
                  <th
                    bgcolor="#B0E170"
                    style={{ color: "#FFFFFF", fontWeight: 500 }}
                  >
                    {t("sub_total")}
                  </th>
                </tr>
                {items()}
                <tr>
                  <td
                    className="subtotal_h"
                    colSpan="3"
                    align="right"
                    bgcolor="#B0E170"
                    style={{ color: "#FFFFFF", fontWeight: 500 }}
                  >
                    {t("sub_total")}
                  </td>
                  <td className="subtotal" align="right">
                    {Get("_order").Total.display}
                  </td>
                </tr>
                <tr>
                  <td
                    className="shippingfee_h"
                    colSpan="3"
                    align="right"
                    bgcolor="#B0E170"
                    style={{ color: "#FFFFFF", fontWeight: 500 }}
                  >
                    {t("discount")}
                  </td>
                  <td className="shippingfee" align="right">
                    {Get("_order").Subtotals.discount.display}
                  </td>
                </tr>
                <tr>
                  <td
                    className="shippingfee_h"
                    colSpan="3"
                    align="right"
                    bgcolor="#B0E170"
                    style={{ color: "#FFFFFF", fontWeight: 500 }}
                  >
                    {t("shipping_fee")}
                  </td>
                  <td className="shippingfee" align="right">
                    {Get("_order").Subtotals.shipping.display}
                  </td>
                </tr>
                <tr>
                  <td
                    className="tax_h"
                    colSpan="3"
                    align="right"
                    bgcolor="#B0E170"
                    style={{ color: "#FFFFFF", fontWeight: 500 }}
                  >
                    {t("vat")}
                  </td>
                  <td className="total" align="right">
                    {Get("_order").Vat_Amount.display}
                  </td>
                </tr>
                <tr className="noborder">
                  <td
                    className="total_h"
                    colSpan="3"
                    align="right"
                    bgcolor="#B0E170"
                    style={{ color: "#FFFFFF", fontWeight: 500 }}
                  >
                    <b>{t("total")}</b>
                  </td>
                  <td className="total" align="right">
                    <b>{Get("_order").Total_Vat.display}</b>
                  </td>
                </tr>
              </table>
            </td>
          </tr>
          <tr>
            <td>
              <hr size="2" color="#B0E170" />
            </td>
          </tr>
          <tr>
            <td>
              <h3>
                <font color="#33212f">{t("mail_contact_us")}</font>
              </h3>
              <p>{t("contact_us_desc")}</p>
              <p>
                <a href="https://www.echelle.co.jp/contact">
                  info@echelle.co.jp
                </a>
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <hr size="2" color="#B0E170" />
            </td>
          </tr>
        </table>

        <div style={{ width: "600px", textAlign: "center", margin: "auto" }}>
          <p style={{ fontWeight: "bold" }}>
            {t("registered_waiting_list_mail_footer")}
          </p>

          <img
            src="https://live.echelle.co.jp/img/header_mailing.png"
            width="90px"
            alt={t("site_name")}
          />

          <p>{t("footer_copyright", { year: moment().year() })}</p>
        </div>
      </div>
    </div>
  );
};

export default Html;
