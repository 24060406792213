import styledComponents from "styled-components";
import { styled } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";

const Div = styledComponents.div``;
const Form = styledComponents.form``;

export const Input = styled(TextField)(style => {
  return {
    background: "white"
  };
});

export const UserInfoWrapper = styled(Div)(style => {
  return {
    textAlign: "center"
  };
});

export const LoginWrapper = styled(Div)(style => {
  return {
    marginTop: style.theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  };
});

export const OAuth2Wrapper = styled(Div)(style => {
  return {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& > *": {
      margin: style.theme.spacing(1)
    }
  };
});

export const OauthBtn = styled(Div)(style => {
  return {
    borderRadius: "50%",
    width: style.theme.spacing(7),
    height: style.theme.spacing(7),
    backgroundPosition: "50%",
    backgroundRepeat: "no-repeat",
    backgroundAttachment: "scroll",
    backgroundSize: "50%",
    overflow: "hidden",
    textIndent: "-1000px",
    cursor: "pointer",
    "&:hover": {
      opacity: 0.4
    },
    "&.twitter": {
      backgroundSize: "60%"
    },
    "&.line": {
      backgroundSize: "70%"
    },
    "&.facebook": {
      backgroundSize: "30%"
    }
  };
});

export const LoginForm = styled(Form)(style => {
  return {
    width: "100%", // Fix IE 11 issue.
    marginTop: style.theme.spacing(1)
  };
});

export const LoginButton = styled(Button)(style => {
  return {
    margin: style.theme.spacing(3, 0, 2),
    "&:hover": {
      opacity: 0.87
    }
  };
});
