import React from "react";

import Grid from "@material-ui/core/Grid";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import { connect } from "react-redux";
import { selectEvent, selectNbTicket } from "store/actions/ReserveAction";
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-kit-react/views/eventPage";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import Button from "components/CustomButtons/Button.js";
import { Link } from "react-router-dom";
import * as NumericInput from "react-numeric-input";

const useStyles = makeStyles(styles);

const BuyTicket = ({
  selected,
  selectEvent,
  user,
  selectNbTicket,
  reserve,
  seats
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const slug = useParams();
  const returnTo = encodeURIComponent(
    "/reserve/" + slug.slug + "/" + reserve.nbTicket
  );

  const onChangeHandler = event => {
    selectNbTicket(event);
  };

  return (
    <>
      {selected && seats.length > 0 && (
        <Grid className={classes.ticketReserve}>
          <GridItem xs={12}>
            <h4 className={classes.subtitleEvent}>
              {t("ticket_available_title")}
            </h4>

            <GridContainer
              container
              direction="row"
              justify="flex-start"
              alignItems="center"
              spacing={3}
            >
              <GridItem xs={12}>
                <GridContainer spacing={1}>
                  {selected &&
                    selected.Planner_Event.Seats.map(seat => (
                      <GridItem xs={12} sm={12} key={seat.Planner_Event_Seat__}>
                        <Grid
                          container
                          direction="row"
                          justify="center"
                          alignItems="center"
                        >
                          <GridItem xs={8}>
                            <h5 className={classes.planName}>
                              {seat.Seat_Name}
                            </h5>
                          </GridItem>

                          <GridItem xs={4}>
                            <h5 className={classes.price}>
                              <span>{seat.Price.display}</span>
                            </h5>
                          </GridItem>

                          <GridItem xs={12} style={{ display: "none" }}>
                            {t("order_choose_number_tickets")}
                            <NumericInput
                              min={1}
                              max={1024}
                              defaultValue={reserve.nbTicket}
                              onChange={onChangeHandler}
                              strict
                            />
                          </GridItem>
                        </Grid>
                      </GridItem>
                    ))}
                </GridContainer>
              </GridItem>

              <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
              >
                <GridItem xs={10} style={{ textAlign: "center" }}>
                  {user.user ? (
                    <Button
                      variant="contained"
                      color="primary"
                      component={Link}
                      to={`/reserve/${selected.Planner_Schedule__}/${reserve.nbTicket}`}
                      onClick={e => selectEvent(selected.Planner_Schedule__)}
                    >
                      {t("reserve_btn")}
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      color="primary"
                      component={Link}
                      to={`/login?return_to=${returnTo}`}
                    >
                      {t("reserve_btn")}
                    </Button>
                  )}
                </GridItem>
              </Grid>
            </GridContainer>
          </GridItem>
        </Grid>
      )}
    </>
  );
};

const mapStateToProps = state => {
  return {
    reserve: state.reserve,
    user: state.user,
    selected: state.schedule.selected,
    loading: state.schedule.loading,
    error: state.schedule.error,
    state: state,
    seats: state.schedule.selected.Planner_Event.Seats
  };
};

const mapDispatchToProps = dispatch => {
  return {
    selectEvent: event => dispatch(selectEvent(event)),
    selectNbTicket: nbTicket => dispatch(selectNbTicket(nbTicket))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BuyTicket);
