import React, { useEffect, useState } from "react";
import { fetchCountries } from "store/actions/CountriesAction";
import { getVat } from "store/actions/OrderAction";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import styles from "assets/jss/material-kit-react/views/orderPage.js";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(styles);

const Countries = ({
  value,
  onChange,
  inputProps = {},
  fetchCountries,
  countries,
  getVat
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const labelRef = React.createRef(null);
  const [labelWidth, setLabelWidth] = useState();

  useEffect(() => {
    if (countries.length !== 0) {
      setLabelWidth(labelRef.current.offsetWidth);
    }
  }, [labelRef, setLabelWidth, countries]);

  useEffect(() => {
    fetchCountries();
  }, [fetchCountries]);

  const handleChange = event => {
    getVat(event.target.value);
    onChange(event.target.value);
  };

  if (countries.length === 0) {
    return null;
  } else {
    return (
      <FormControl
        variant="outlined"
        className={classes.formControl}
        {...inputProps}
      >
        <InputLabel ref={labelRef} id="select-country">
          {t("select_country")}
        </InputLabel>
        <Select
          labelId="select-country"
          onChange={handleChange}
          value={value}
          labelWidth={labelWidth}
          native
          required
        >
          <option />
          {countries.map(country => (
            <option key={country.Country__} value={country.Country__}>
              {country.Name}
            </option>
          ))}
        </Select>
      </FormControl>
    );
  }
};

const mapStateToProps = state => {
  return {
    countries: state.countries.countries
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchCountries: () => dispatch(fetchCountries()),
    getVat: country => dispatch(getVat(country))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Countries);
