import React from "react";
import { Get } from "@karpeleslab/klbfw";
import { useTranslation } from "react-i18next";

const Html = () => {
  const { t } = useTranslation();
  return (
    <div id="mail-html">
      <div
        style={{
          textAlign: "center",
          width: "30%",
          marginLeft: "auto",
          marginRight: "auto",
          marginBottom: "20px"
        }}
      >
        <div
          style={{
            margin: "auto",
            textAlign: "Left"
          }}
        >
          <p>
            {t("mail_user_name", { name: Get("_user").Profile.Display_Name })}
          </p>
          <p>{t("mail_registration_completed_text_01")}</p>
          <p>{t("mail_registration_completed_text_02")}</p>
          <p>{t("mail_common_text_03")}</p>
          <p>{t("mail_common_text_01")}</p>
          <p>{t("mail_common_text_02")}</p>
          <p>{t("mail_name")}</p>
        </div>
      </div>
    </div>
  );
};

export default Html;
