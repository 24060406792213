import {
  primaryColor,
  secondaryTransparencyColor
} from "assets/jss/material-kit-react.js";
import Background from "assets/img/bg.jpg";
import FollowMe from "assets/img/follow-me.png";

const landingPageStyle = {
  titlePage: {
    marginBottom: 0,
    color: "white",
    fontWeight: "400",
    minHeight: "auto",
    //fontSize: "56px",
  },
  title: {
    display: "inline-block",
    position: "relative",
    marginTop: "30px",
    minHeight: "32px",
    color: primaryColor,
    textDecoration: "none",
    textTransform: "uppercase",
    fontSize: "32px"
  },
  content: {
    //fontSize: "1.313rem",
    margin: "10px auto 0",
    color: primaryColor
  },
  container: {
    background: secondaryTransparencyColor,
    maxWidth: "1200px",
    margin: "0px auto 0",
    borderRadius: "6px"
  },
  main: {
    background: "transparent",
    position: "relative",
    zIndex: "3"
  },
  mainRaised: {
    paddingTop: "80px",
    margin: "15px",
    /*padding:"32px",
    margin: "0px 30px 0px",*/
    borderRadius: "6px"
    /* boxShadow:
      "0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)"*/
  },
  background: {
    backgroundImage: "url(" + Background + ")",
    backgroundSize: "cover",
    backgroundRepeat: " no-repeat",
    backgroundAttachment: "fixed",
    background: "#000000"
    //    height: "calc(100vh - 70px)",
  },
  nobackground: {
    backgroundImage: "unset",
    backgroundSize: "cover",
    backgroundRepeat: " no-repeat",
    backgroundAttachment: "fixed",
    background: "#000000"
  },
  filter: {
    "&:before": {
      background: "rgba(0, 0, 0, 0.5)"
    },
    "&:after,&:before": {
      position: "fixed",
      zIndex: "1",
      width: "100%",
      height: "100%",
      display: "block",
      left: "0",
      top: "0",
      content: "''"
    }
  },
  tablecell: {
    color: primaryColor
  },
  twitter: {
    position: "fixed",
    top: "300px",
    width: "30px",
    height: "119px",
    zIndex: 9999,
    cursor: "pointer",
    right: 0,
    background: `#35ccff url(${FollowMe})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "left top"
  }
};

export default landingPageStyle;
