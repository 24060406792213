import React, { useEffect } from "react";

import Grid from "@material-ui/core/Grid";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import { connect } from "react-redux";
import { reservedSeatByEvent } from "store/actions/ReserveAction";
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-kit-react/views/eventPage";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(styles);

const ReservedSeatsByEvent = ({
  reservedSeatByEvent,
  seats,
  schedule,
  loading
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  useEffect(() => {
    if (!schedule) return;
    reservedSeatByEvent(schedule.Planner_Event__);
  }, [reservedSeatByEvent, schedule]);

  return (
    <Grid className={classes.ticketReserved}>
      {seats.length > 0 && !loading ? (
        <GridItem xs={12}>
          <h4 className={classes.subtitleEvent}>{t("reserved_tickets")}</h4>

          <GridItem xs={10}>
            <GridContainer spacing={1}>
              {seats &&
                seats.map(
                  seat =>
                    seat.Status === "reserve_paid" && (
                      <GridItem
                        xs={12}
                        sm={12}
                        key={seat.Planner_Event_Seat_Reserve__}
                      >
                        <h5>{seat.Planner_Event.Name}</h5>
                      </GridItem>
                    )
                )}
            </GridContainer>
          </GridItem>
        </GridItem>
      ) : (
        <p style={{ textAlign: "center", padding: "6px", marginBottom: "0" }}>
          {t("no_seat_reserved_for_this_event_p")}
        </p>
      )}
    </Grid>
  );
};

const mapStateToProps = state => {
  return {
    seats: state.reserve.seats,
    loading: state.schedule.loading,
    error: state.schedule.error,
    state: state,
    schedule: state.schedule.selected
  };
};

const mapDispatchToProps = dispatch => {
  return {
    reservedSeatByEvent: id => dispatch(reservedSeatByEvent(id))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReservedSeatsByEvent);
