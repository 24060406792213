import React from "react";
import TableRow from "@material-ui/core/TableRow";
import Skeleton from "@material-ui/lab/Skeleton";
import TableCell from "@material-ui/core/TableCell";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { FaFileDownload } from "react-icons/fa";

const Row = ({ order, loading }) => {
  const displayUser = !loading && order;
  const { t } = useTranslation();
  return (
    <TableRow>
      <TableCell>
        {!displayUser && <Skeleton />}
        {displayUser && <span>{order.Invoice_Number}</span>}
      </TableCell>
      <TableCell>
        {!displayUser && <Skeleton />}
        {displayUser && (
          <span>
            {order.Invoice_Date &&
              moment(parseInt(order.Invoice_Date.unixms)).format("LLL")}
          </span>
        )}
      </TableCell>
      <TableCell>
        {!displayUser && <Skeleton />}
        {displayUser && (
          <span>
            {order.Paid && moment(parseInt(order.Paid.unixms)).format("LLL")}
          </span>
        )}
      </TableCell>
      <TableCell>
        {!displayUser && <Skeleton />}
        {displayUser && <span>{t(`status_${order.Status ?? "-"}`)}</span>}
      </TableCell>
      <TableCell>
        {!displayUser && <Skeleton />}
        {displayUser && <span>{order.Total_Vat.display ?? "-"}</span>}
      </TableCell>
      <TableCell align="right">
        {!displayUser && <Skeleton />}
        {displayUser && (
          <a href={order.Invoice_Url} target="blank">
            <FaFileDownload style={{ fontSize: "24px", color: "black" }} />
          </a>
        )}
      </TableCell>
    </TableRow>
  );
};

const mapStateToProps = (state, { loading, idx }) => {
  if (loading) return {};
  return {
    order: state.orderHistory.data[idx]
  };
};

export default connect(
  mapStateToProps,
  null
)(Row);
