import React from "react";
import Helmet from "components/instances/instance/Helmet/Helmet";

// core components
import Grid from "@material-ui/core/Grid";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import { useTranslation } from "react-i18next";

// Sections for this page
import FlowLogin from "components/login/Login";

//style
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-kit-react/views/common.js";
const useStyles = makeStyles(styles);

export default function LandingPage(props) {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <div>
      <Helmet title={t("helmet_echelle_login")} />

      <Grid
        container
        direction="row"
        justify="center"
        alignItems="center"
        style={{ background: "#5B9797", marginBottom: "24px" }}
      >
        <h2 className={classes.titlePage}>{t("login_title")}</h2>
      </Grid>

      <GridContainer justify="center">
        <GridItem xs={12} md={8} lg={8}>
          <FlowLogin />
        </GridItem>
      </GridContainer>
    </div>
  );
}
