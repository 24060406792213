import React, { useEffect, useState } from "react";
import Helmet from "components/instances/instance/Helmet/Helmet";

import Grid from "@material-ui/core/Grid";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import { connect } from "react-redux";
import { fetchEvent } from "store/actions/ScheduleAction";
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-kit-react/views/eventPage";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import moment from "moment";
import "moment/locale/ja";
import { Link } from "react-router-dom";

import Warning from "@material-ui/icons/Warning";

import SimpleReactLightbox from "simple-react-lightbox"; // Import Simple React Lightbox
import { SRLWrapper } from "simple-react-lightbox"; // Import SRLWrapper
import "./style.scss";
import defaultEvent from "assets/img/defaultEvent.jpg";

//Imports others pages
import BuyTicket from "./BuyTicket";
import ReservedTickets from "./ReservedTickets";

import { FaRegCalendarAlt } from "react-icons/fa";
import { FaRegClock } from "react-icons/fa";

import sassStyles from "../../../Scss/event.module.scss";

export const IMG_VARIATION = "strip&scale=470x600&format=jpeg";
const useStyles = makeStyles(styles);

const Event = ({ fetch, selected, user }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  let { slug } = useParams();
  const [loading, setLoading] = useState(true);
  moment.locale("ja");
  const returnTo = encodeURIComponent("/event/" + slug);

  useEffect(() => {
    fetch(slug).then(v => setLoading(false));
  }, [fetch, slug]);

  return (
    <>
      <Helmet title={t("helmet_echelle_event")} />
      {selected && !loading && (
        <GridContainer spacing={8} className={classes.root}>
          <GridItem xs={12} sm={12}>
            <div
              className={classes.titleGroup}
            >
              <h2 className={classes.titleEvent}>{selected.Label}</h2>
              {selected.Content_Cms_Entry_Data.Short_Contents.length !== 0 && <p className={classes.titleShort}>{selected.Content_Cms_Entry_Data.Short_Contents}</p>}
            </div>

            <GridContainer>
              <GridItem xs={12}>
                {user.user ? (
                  <p
                    className={sassStyles["to-login"]}
                    style={{
                      textAlign: "center",
                      whiteSpace: "pre-line",
                      marginBottom: "24px"
                    }}
                  >
                    <Link to={`/live/${slug}`}>
                      <span>
                        {t("go_live_page")}
                      </span>
                    </Link>
                  </p>
                ) : (
                  <p
                    className={sassStyles["to-login"]}
                    style={{
                      textAlign: "center",
                      whiteSpace: "pre-line",
                      marginBottom: "24px"
                    }}
                  >
                    <Link to={`/login?return_to=${returnTo}`}>
                      <span>
                        <Warning />
                        {t("reserved_but_not_currently_logged")}
                      </span>
                    </Link>
                  </p>
                )}
              </GridItem>
            </GridContainer>

            <GridContainer>
              <GridItem xs={12}>
                <GridContainer>
                  <GridItem xs={12} sm={5}>
                    {selected.Content_Cms_Entry_Data.Drive_Item ? (
                      <SimpleReactLightbox>
                        <SRLWrapper>
                          <a
                            href={
                              selected.Content_Cms_Entry_Data.Drive_Item
                                .View_Url
                            }
                            data-attribute="SRL"
                          >
                            <img
                              src={
                                selected.Content_Cms_Entry_Data.Drive_Item
                                  .Media_Image[IMG_VARIATION]
                              }
                              style={{
                                width: "100%",
                                border: "solid 1px #05d1d6"
                              }}
                              alt="Event"
                            />
                          </a>
                        </SRLWrapper>
                      </SimpleReactLightbox>
                    ) : (
                      <SimpleReactLightbox>
                        <SRLWrapper>
                          <a href={defaultEvent} data-attribute="SRL">
                            <img
                              src={defaultEvent}
                              style={{ width: "100%" }}
                              alt="Event"
                            />
                          </a>
                        </SRLWrapper>
                      </SimpleReactLightbox>
                    )}

                    {selected.Planner_Event.Status === "reserve_open" && (
                      <BuyTicket />
                    )}

                    {selected.Planner_Event.Status === "pending" && (
                      <Grid className={classes.ticketReserved}>
                        <GridItem xs={12}>
                          <GridItem xs={12}>
                            <GridContainer spacing={1}>
                              <h5 style={{ textAlign: "center" }}>
                                {t("event_ticket_available_soon_p", {
                                  date: moment(
                                    parseInt(
                                      selected.Planner_Event.Reservation_Start
                                        .unixms
                                    )
                                  ).format("LL")
                                })}
                              </h5>
                            </GridContainer>
                          </GridItem>
                        </GridItem>
                      </Grid>
                    )}

                    {user.user && <ReservedTickets />}
                  </GridItem>
                  <GridItem xs={12} sm={7}>
                    <div className={classes.timeEvent}>
                      <span className={classes.date}>
                        {" "}
                        <FaRegCalendarAlt />{" "}
                        {moment(
                          parseInt(selected.Planner_Event.Event_Date.unixms)
                        ).format("LL")}{" "}
                      </span>
                      <br />
                      <FaRegClock />{" "}
                      {moment(
                        parseInt(selected.Planner_Event.Event_Date.unixms)
                      ).format("LT")}
                    </div>

                    <div
                      dangerouslySetInnerHTML={{
                        __html:
                          selected.Content_Cms_Entry_Data &&
                          selected.Content_Cms_Entry_Data.Contents
                      }}
                    />
                  </GridItem>
                </GridContainer>
              </GridItem>
            </GridContainer>
          </GridItem>
        </GridContainer>
      )}
    </>
  );
};

const mapStateToProps = state => {
  return {
    user: state.user,
    selected: state.schedule.selected,
    error: state.schedule.error,
    state: state
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetch: slug =>
      dispatch(fetchEvent(slug, { image_variation: IMG_VARIATION }))
  };
};

Event.fetchEvent = (match, store) =>
  store.dispatch(fetchEvent(match.params.slug));

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Event);
