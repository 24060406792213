import React, { useCallback, useEffect, useRef, useState } from "react";
import classNames from "classnames";
import { rest } from "@karpeleslab/klbfw";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useRest } from "@karpeleslab/react-klbfw-hooks";
import ReactTooltip from 'react-tooltip';
import moment from "moment";

// icon
import Close from "@material-ui/icons/Close";
import PlayArrow from "@material-ui/icons/PlayArrow"
import ArrowDownward from "@material-ui/icons/ArrowDownward"
import Settings from "@material-ui/icons/Settings"

// style
import style from "Scss/live.module.scss";

// img
import DefaultAvatar from "assets/img/avatar.png";

// compontnts
import { error as errorToast } from "store/actions/ToastAction";

const Chat = (props) => {

	const { setChatStatus, user, streamHeight, chatStatus, Planner_Event__, stream, slug, ticketStatus, scrollLock, useChat } = props;
	const chatRef = useRef(null);
	const chatInput = useRef(null)
	const initialAvatar = user.Profile.Media_Image　? user.Profile.Media_Image.Url　: DefaultAvatar;
	const [chat, setChat] = useState('');
	const [chatLog, setChatLog] = useState([]);
	const [send, setSend] = useState(false)
	const [chatLimit, setChatLimit] = useState(0);
	const [chatScrollStatus, setChatScrollStatus] = useState(true);
	const [ChatLogApi, setChatLogApi] = useState(false);
	const { t } = useTranslation();
	const [chatAllCount] = useRest(`Planner/Event/${Planner_Event__}/Chat`, "GET");
	
	const chatSubmit = (e) => {
		e.preventDefault();
		
		const query = {
			message: chat
		};

		setSend(true);

    rest(`Planner/Event/${Planner_Event__}/Chat:post`, "POST", query)
		.then(data => {
			setChat('');
			chatInput.current.blur();
			setChatScrollStatus(true)

			setChatLimit(data.data.Time_Limit)
			setSend(false);

			chatRef.current.scrollTop = chatRef.current.scrollHeight;
		})
		.catch(data => {
			errorToast(t(data.message));
			setSend(false);
		});
	}

	useEffect(() => {

		if (chatLimit !== 0) {
			setTimeout(() => {
				setChatLimit(chatLimit - 1);
			}, 1000)
		}

	}, [chatLimit])


	const buildImg = useCallback((val) => {

		if (val.Profile.Media_Image) {
			return val.Profile.Media_Image.Url
		} else {
			return DefaultAvatar
		}
	}, [])

	const handleChatScroll = useCallback(() => {
		if (chatStatus) {
			setTimeout(() => {
				chatRef.current.scrollTop = chatRef.current.scrollHeight;
			}, 1)
		}
	},[chatStatus]);

	const buildList = useCallback(() => {

		if (chatLog.length === 0) return false;

		return chatLog.map((val, index) => {
			if (val.Type !== 'text') return false;

			return (
				<>
					<div
						key={val.Index}
						className={classNames(style['chat-body-item'])}
					>
						<figure
							className={style['chat-body-img']}
						>
							<img src={buildImg(val)} alt="a" />
						</figure>
						<dl
							className={style['chat-body-name']}
						>
							<dt>{val.Profile.Display_Name}</dt>
							<dd
								data-tip={`投稿時間：${moment(parseInt(val.Date.unixms)).format('YYYY/MM/DD HH:mm:ss')}`}
							>{val.Message}</dd>
						</dl>
						<ReactTooltip
							key={val.Index}
						/>
					</div>
				</>
			)
		})

	}, [chatLog, buildImg])

  const updateChat = useCallback(() => {

		setChatLogApi(true)

		let query = {
			index: 0
		}

		if (chatLog.length !== 0 ) {
			query.index = chatLog.slice(-1)[0].Index;
		}
		
    rest(`Planner/Event/${Planner_Event__}/Chat:poll`, "POST", query)
			.then(res => {

				// APIの動作確認様
				setChatLogApi(false)

				setChatLog(
					[
						...chatLog,
						...res.data
					]
				)
			})
			.catch(data => {
			});
		
	}, [ chatLog, Planner_Event__ ]);


	
  useEffect(() => {
		if(chatAllCount !== null) {
			if (chatAllCount.paging.count >= chatLog.length && chatScrollStatus) {
				chatRef.current.scrollTop = chatRef.current.scrollHeight;
			}
		}
	}, [chatLog, chatAllCount, chatScrollStatus]);

	
  useEffect(() => {

		if (chatStatus !== false && !ChatLogApi) {
			updateChat();
		}

	}, [updateChat, chatStatus, ChatLogApi]);

	const buildBuyBtn = () => {

    switch (ticketStatus) {
      case 'past':
        return (
					<div
						className={style['chat-input-buy']}
					>
						<p>開催済のライブ配信です。</p>
					</div>
				)
			case 'reserve_open':
        return (
					<div
						className={style['chat-input-buy']}
					>
						<p>チャットにはチケットの購入が必要です。</p>
						<Link to={`/reserve/${slug}/1`}>チケット購入</Link>
					</div>
				)
			case 'reserve_closed':
        return (
					<div
						className={style['chat-input-buy']}
					>
						<p>チケットの販売は終了しました。</p>
					</div>
				)
      default:
        return (
					<div
						className={style['chat-input-buy']}
					>
						<p>準備中です。</p>
					</div>
				)
    }
	}

	const buildForm = () => {
		if ( stream.Has_Seat ) {

			if ( useChat.boolean ) {
				return (
					<form
						onSubmit={(e) => {chatSubmit(e)}}
						className={style['chat-input-form']}
					>
						{chatLimit !== 0 &&
							<div
								className={style['chat-input-limit']}
							>
								<p
									className={style['chat-input-limit-attention']}
								>投稿可能まで：{chatLimit}秒</p>
							</div>
						}
						<div
							className={style['chat-input-user']}
						>
							<figure
								className={style['chat-input-user-img']}
							>
								<img src={initialAvatar} alt="a" />
							</figure>
							<dl
								className={style['chat-input-name']}
							>
								<dt><span>{user.Profile.Display_Name}</span><Link to={'/account#information'}><Settings /></Link></dt>
								<dd>
									<input
										type="text"
										value={chat}
										onChange={(e) => {setChat(e.target.value)}}
										placeholder="こちらから入力できます。"
										ref={chatInput}
									/>
									<button
										type="submit"
										disabled={chat.length === 0 || chatLimit !== 0 || send}
									><PlayArrow /></button>
								</dd>
							</dl>
						</div>
					</form>
				)
			} else {
				if (useChat.type === 'before') {
					return (
						<div
							className={style['chat-input-buy']}
						>
							<p>ライブ開始15分前から利用できます。</p>
						</div>
					)
				} else if (useChat.type === 'after') {
					return (
						<div
							className={style['chat-input-buy']}
						>
							<p>チャットは終了しました。</p>
						</div>
					)
				}
			}
		} else {
			return buildBuyBtn()
		}
	}

	const scrollHandller = (e) => {
		
		const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight
		
		if (bottom) {
			setChatScrollStatus(true)
		} else {
			setChatScrollStatus(false)
		}

	}

	useEffect(() => {
		setTimeout(() => {
			chatRef.current.scrollTop = chatRef.current.scrollHeight;
		}, 1)
	}, [chatStatus])

  return (
		<>
    <div
			className={classNames(style['chat'], {
				[style['chat-active']]: !chatStatus
			})}
			style={{
				height: `${streamHeight}px`
			}}
    >
			 {
			 	scrollLock && <style>{"\
					body{\
						overflow:hidden;\
					}\
				"}</style>
			}

			<div
				className={style['chat-header']}
			>
				<button
					onClick={() => {setChatStatus(false)}}
					className={style['chat-header-close']}
				><Close /></button>
			</div>
			<div
				className={style['chat-body']}
				ref={chatRef}
				onScroll={scrollHandller}
			>
				<div
					className={style['chat-body-inner']}
				>
					{buildList()}
				</div>
			</div>

			{!chatScrollStatus && <button
				onClick={() => {handleChatScroll()}}
				className={style['chat-bottom-btn']}
			><ArrowDownward /></button> }

			<div
				className={style['chat-input']}
			>
				{buildForm()}
			</div>
    </div>
		</>
  );
};

export default Chat;
