import React from "react";
import Helmet from "components/instances/instance/Helmet/Helmet";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import styles from "assets/jss/material-kit-react/views/landingPage.js";
import { useTranslation } from "react-i18next";

// Sections for this page
import ListEvents from "./Sections/ListEvents";

const useStyles = makeStyles(styles);

export default function LandingPage(props) {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <div className={classes.section}>
      <Helmet title={t("helmet_echelle_home")} />

      <ListEvents />
    </div>
  );
}
