import React from "react";
//import { useTranslation } from "react-i18next";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Table from "./Table";

const PurchasedTickets = () => {
  //const { t } = useTranslation();

  return (
    <GridContainer spacing={3}>
      <GridItem xs={12}>
        <Table />
      </GridItem>
    </GridContainer>
  );
};

export default PurchasedTickets;
