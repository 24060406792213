import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import TextField from "@material-ui/core/TextField";
import BoxedText from "components/common/feedback/BoxedText";
import { forgetPassword } from "store/actions/UserActions";
import Button from "components/CustomButtons/Button";

const Form = ({ forgetPassword }) => {
  const { t } = useTranslation();
  const [sent, setSent] = useState(false);
  const [email, setEmail] = useState("");
  const [sendEnabled, setSendEnabled] = useState(false);

  useEffect(() => {
    setSendEnabled(email.trim() !== "");
  }, [email, setSendEnabled]);

  const handleSend = () => {
    setSent(true);
    forgetPassword(email);
  };

  if (sent) {
    return (
      <BoxedText
        disabledGutter
        title={t("forgot_password_sent_title")}
        description={t("forgot_password_sent_desc")}
      />
    );
  }

  return (
    <GridContainer
      spacing={3}
      direction="column"
      alignItems="center"
      justify="center"
    >
      <GridItem xs={12} md={6}>
        <TextField
          label={t("password_forget_email")}
          placeholder={t("password_forget_email")}
          required
          fullWidth
          value={email}
          onChange={e => setEmail(e.target.value)}
          helperText={t("password_forget_email_helperText")}
          variant="outlined"
        />
      </GridItem>
      <GridItem xs={12} md={4}>
        <Button
          variant="contained"
          color="primary"
          disabled={!sendEnabled}
          fullWidth
          onClick={handleSend}
        >
          {t("forgot_password_send_btn")}
        </Button>
      </GridItem>
    </GridContainer>
  );
};

const mapDispatchToProps = dispatch => {
  return {
    forgetPassword: email => dispatch(forgetPassword(email))
  };
};

export default connect(
  null,
  mapDispatchToProps
)(Form);
