import React, { useEffect } from "react";
import TableHead from "@material-ui/core/TableHead";
import MaterialTable from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import Row from "./Row";
import { connect } from "react-redux";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";

import {
  reservedSeats,
  setTicketsReservedHistoryPaging
} from "store/actions/ReserveAction";
import { useTranslation } from "react-i18next";

import { makeStyles, useTheme } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";
import PropTypes from "prop-types";

const useStyles = makeStyles(theme => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5)
  }
}));

function TablePaginationActions(props) {
  const classes = useStyles();
  const theme = useTheme();
  const { count, page, rowsPerPage, onChangePage } = props;

  const handleFirstPageButtonClick = event => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = event => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = event => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = event => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired
};

const Table = ({
  history,
  fetch,
  loading,
  paging,
  count,
  setTicketsReservedHistoryPaging,
  disabled = false
}) => {
  useEffect(() => {
    fetch();
  }, [fetch, paging]);

  const loadingData = [...Array(paging.results_per_page).keys()];
  const { t } = useTranslation();
  const displayLoading = () =>
    loadingData.map(k => <Row key={k} loading={true} user={null} />);
  const displayTicketsReserved = () =>
    history.map((u, idx) => (
      <Row key={u.Planner_Event_Seat_Reserve__} loading={false} idx={idx} />
    ));

  const changePageHandler = (e, newPage) => {
    // The pagination component start at 0;...
    if (loading) return;
    setTicketsReservedHistoryPaging({ page_no: newPage + 1 });
  };

  const changeResultPerPageHandler = e => {
    if (loading) return;
    setTicketsReservedHistoryPaging({ results_per_page: e.target.value });
  };

  return (
    <TableContainer>
      <MaterialTable aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell style={{ minWidth: "120px" }}>
              {t("history_reserved_date")}
            </TableCell>
            <TableCell style={{ minWidth: "120px" }}>
              {t("history_reserved_event_name")}
            </TableCell>
            <TableCell style={{ minWidth: "120px" }}>
              {t("history_reserve_event_date")}
            </TableCell>
            <TableCell style={{ minWidth: "120px" }}>
              {t("history_reserved_location")}
            </TableCell>
            <TableCell style={{ minWidth: "120px" }}>
              {t("history_reserved_status")}
            </TableCell>
            <TableCell style={{ minWidth: "120px" }}>
              {t("history_reserved_price")}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {(loading || disabled) && displayLoading()}
          {!loading && !disabled && displayTicketsReserved()}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              colSpan={6}
              count={loading ? loadingData.length : parseInt(count)}
              rowsPerPage={parseInt(paging.results_per_page)}
              page={loading ? 0 : parseInt(paging.page_no) - 1}
              SelectProps={{
                inputProps: { "aria-label": t("rows_per_page") },
                native: true
              }}
              onChangePage={changePageHandler}
              onChangeRowsPerPage={changeResultPerPageHandler}
              ActionsComponent={TablePaginationActions}
              labelRowsPerPage={t("rows_per_page")}
            />
          </TableRow>
        </TableFooter>
      </MaterialTable>
    </TableContainer>
  );
};

const mapStateToProps = state => {
  return {
    paging: state.reserve.historyPaging,
    count: state.reserve.historyPagingCount,
    loading: state.reserve.loading,
    history: state.reserve.allReservedSeats
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetch: () => dispatch(reservedSeats()),
    setTicketsReservedHistoryPaging: newPaging =>
      dispatch(setTicketsReservedHistoryPaging(newPaging))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Table);
