import React from "react";
import { Provider } from "react-redux";
import createStore, { setAsCurrentStore } from "../store/store";
import toString from "./toString";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { getInitialState, getPrefix, rest } from "@karpeleslab/klbfw";
import Layout from "components/layout/Layout";

import "assets/scss/material-kit-react.scss?v=1.8.0";
import { prerequisites } from "./routeConfig";

import "i18n";

function hydrate(store) {
  ReactDOM.hydrate(
    <Provider store={store}>
      <BrowserRouter basename={getPrefix()}>
        <Layout />
      </BrowserRouter>
    </Provider>,
    document.getElementById("root")
  );
}

export function run() {
  if (process.env.NODE_ENV !== "production" && typeof window !== "undefined") {
    window.rest = rest;
  }

  const initState = getInitialState();
  const store = createStore(initState);
  setAsCurrentStore(store);

  if (initState) hydrate(store);
  else {
    Promise.all(prerequisites(store)).then(() => hydrate(store));
  }
}

export const renderToString = toString;
