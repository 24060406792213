import React from "react";
import { Grid } from "@material-ui/core";
import Picture from "./Picture";
import ChangeEmail from "./ChangeEmail";

const Account = () => {
  return (
    <Grid container spacing={3} alignItems="center">
      <Grid item xs={12}>
        <Picture />
      </Grid>
      <Grid item xs={12}>
        <ChangeEmail />
      </Grid>
    </Grid>
  );
};

export default Account;
