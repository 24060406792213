const initState = {
  data: [],
  error: null,
  loading: true,
  historyPagingCount: 0,
  historyPaging: {
    page_no: 1,
    results_per_page: 10
  }
};

export const FETCH_ORDER_HISTORY_BEGIN = "FETCH_ORDER_HISTORY_BEGIN";
export const FETCH_ORDER_HISTORY_SUCCESS = "FETCH_ORDER_HISTORY_SUCCESS";

export const HISTORY_SET_PAGING = "HISTORY_SET_PAGING";

const orderHistoryReducer = (state = initState, action) => {
  switch (action.type) {
    case FETCH_ORDER_HISTORY_BEGIN:
      // Mark the state as "loading" so we can show a spinner or something
      // Also, reset any errors. We're starting fresh.
      return {
        ...state,
        loading: true,
        error: null
      };

    case FETCH_ORDER_HISTORY_SUCCESS:
      // All done: set loading "false".
      // Also, replace the items with the ones from the server
      return {
        ...state,
        loading: false,
        data: action.data,
        historyPagingCount: action.paging.count
      };

    case HISTORY_SET_PAGING:
      return { ...state, historyPaging: action.paging };

    default:
      return state;
  }
};

export default orderHistoryReducer;
