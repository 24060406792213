const initState = () => {
  return {};
};

export const COMMON_RESET = "COMMON_RESET";
export const USER_LOGOUT = "USER_LOGOUT";

const commonReducer = (state = initState(), action) => {
  switch (action.type) {
    case USER_LOGOUT:
      return { ...initState };
    default:
      return state;
  }
};

export default commonReducer;
