const initState = {
  entry: null,
  loadingEntry: true,
  error: null,
  cmsList: [],
  cmsLoading: true,
  cmsError: null,
  paging: {
    count: 0,
    page_max: 0,
    page_no: 1,
    results_per_page: 10
  }
};

export const SYNC_CMS = "SYNC_CMS";
export const SYNC_CMS_DONE = "SYNC_CMS_DONE";
export const SYNC_CMS_ERROR = "SYNC_CMS_ERROR";

export const SYNC_CMS_LIST = "SYNC_CMS_LIST";
export const SYNC_CMS_LIST_DONE = "SYNC_CMS_LIST_DONE";
export const SYNC_CMS_LIST_ERROR = "SYNC_CMS_LIST_ERROR";

const cmsReducer = (state = initState, action) => {
  switch (action.type) {
    case SYNC_CMS:
      return { ...state, loadingEntry: true, entry: null, error: null };
    case SYNC_CMS_ERROR:
      return { ...state, loadingEntry: false, error: action.error };
    case SYNC_CMS_DONE:
      return { ...state, loadingEntry: false, entry: action.entry };
    case SYNC_CMS_LIST:
      return { ...state, cmsLoading: true, cmsError: null };
    case SYNC_CMS_LIST_DONE:
      return {
        ...state,
        cmsLoading: false,
        cmsList: action.list,
        paging: action.paging
      };
    case SYNC_CMS_LIST_ERROR:
      return { ...state, cmsLoading: false, cmsError: action.error };
    default:
      return state;
  }
};

export default cmsReducer;
