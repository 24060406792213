import React, { useEffect, useState } from "react";
import { Grid, TextField } from "@material-ui/core";
import { connect } from "react-redux";
import RequestPasswordDialog from "./RequestPasswordDialog";
import { useTranslation } from "react-i18next";
import { setEmail } from "store/actions/UserActions";
import Button from "components/CustomButtons/Button.js";

const ChangeEmail = ({ user, changingInfo, setEmailApi }) => {
  const { t } = useTranslation();

  const [requestPwdOpen, setRequestPwdOpen] = useState(false);
  const [email, setEmail] = useState(user.Email);
  const [saveEnabled, setSaveEnabled] = useState(false);

  useEffect(() => {
    if (email !== user.Email) {
      setSaveEnabled(true);
      return;
    }
    setSaveEnabled(false);
  }, [email, user]);

  const handleChangeEmail = pwd => {
    setEmailApi(email, pwd);
  };

  return (
    <Grid
      container
      spacing={3}
      direction="row"
      justify="center"
      alignItems="center"
    >
      <Grid item xs={10} md={9}>
        <TextField
          variant="outlined"
          disabled={changingInfo}
          fullWidth
          label={t("profile_email")}
          value={email}
          type="email"
          onChange={e => setEmail(e.target.value)}
        />
      </Grid>
      <Grid item xs={10} md={9}>
        <Grid container justify="flex-end">
          <Button
            color="primary"
            variant="contained"
            disabled={!saveEnabled || changingInfo}
            onClick={() => {
              if (user.Has_Password) setRequestPwdOpen(true);
              else handleChangeEmail(null);
            }}
          >
            {t("save_btn")}
          </Button>
        </Grid>
      </Grid>
      {user.Has_Password && (
        <RequestPasswordDialog
          open={requestPwdOpen}
          setOpen={setRequestPwdOpen}
          closeCallback={handleChangeEmail}
        />
      )}
    </Grid>
  );
};

const mapStateToProps = state => {
  return {
    user: state.user.user,
    changingInfo: state.user.changingInfo
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setEmailApi: (email, password) => dispatch(setEmail(email, password))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChangeEmail);
