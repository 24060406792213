import React, { Component } from "react";
import { connect } from "react-redux";
import Snackbar from "@material-ui/core/Snackbar";
import { consumed } from "../../../store/actions/ToastAction";
import Alert from "@material-ui/lab/Alert";

class Toasts extends Component {
  timeout = null;

  handleClose = (e, reason) => {
    if (reason === "clickaway") return false;
    clearTimeout(this.timeout);
    this.props.consumed(this.props.toast);
  };

  handleTimeout = () => {
    this.props.consumed(this.props.toast);
  };

  componentDidMount() {
    const { toast } = this.props;
    if (!toast) return;
    this.timeout = setTimeout(this.handleTimeout, toast.duration);
  }

  componentWillUnmount() {
    clearTimeout(this.timeout);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.toast && !prevProps.toast) {
      this.timeout = setTimeout(this.handleTimeout, this.props.toast.duration);
    } else if (this.props.toast && this.props.toast.id !== prevProps.toast.id) {
      this.timeout = setTimeout(this.handleTimeout, this.props.toast.duration);
    }
  }

  render() {
    const { toast } = this.props;

    return (
      <Snackbar
        open={toast !== null}
        onClose={this.handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center"
        }}
      >
        <Alert
          onClose={this.handleClose}
          severity={!toast ? "info" : toast.type}
        >
          {/*We can here test toast.i18n to check if the message is a token or not*/}
          {!toast ? "" : toast.message}
        </Alert>
      </Snackbar>
    );
  }
}

const mapStateToProps = state => {
  return {
    toast: state.toast.current
  };
};

const mapDispatchToProps = dispatch => {
  return {
    consumed: toast => dispatch(consumed(toast))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Toasts);
