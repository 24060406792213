const initState = {
  loading: true,
  stripeKey: null,
  stripeOptions: null,
  token: null,
  complete: false,
  error: null,
  tokenError: null,
  stripe: null,
  processing: false,
  status: {
    complete: false
  }
};

export const STRIPE_PAYMENT_FETCH_INFO = "STRIPE_PAYMENT_FETCH_INFO";
export const STRIPE_PAYMENT_FETCH_INFO_DONE = "STRIPE_PAYMENT_FETCH_INFO_DONE";
export const STRIPE_PAYMENT_TOKEN_RECEIVED = "STRIPE_PAYMENT_TOKEN_RECEIVED";
export const STRIPE_PAYMENT_COMPLETED = "STRIPE_PAYMENT_COMPLETED";
export const STRIPE_PAYMENT_ERROR = "STRIPE_PAYMENT_ERROR";
export const STRIPE_SET_OBJECT = "STRIPE_SET_OBJECT";
export const STRIPE_SET_PROCESSING = "STRIPE_SET_PROCESSING";

export const STRIPE_SET_STATUS = "STRIPE_SET_STATUS";

const stripePaymentReducer = (state = initState, action) => {
  switch (action.type) {
    case STRIPE_SET_STATUS:
      return { ...state, status: action.status };
    case STRIPE_PAYMENT_FETCH_INFO:
      return {
        ...state,
        loading: true,
        token: null,
        complete: false,
        processing: false,
        error: null,
        stripe: null
      };
    case STRIPE_PAYMENT_FETCH_INFO_DONE:
      return {
        ...state,
        loading: false,
        stripeKey: action.stripeKey,
        stripeOptions: action.stripeOptions
      };
    case STRIPE_PAYMENT_TOKEN_RECEIVED:
      return { ...state, token: action.token, complete: false, error: null };
    case STRIPE_PAYMENT_COMPLETED:
      return { ...state, complete: true, error: null, processing: false };
    case STRIPE_PAYMENT_ERROR:
      return {
        ...state,
        complete: false,
        error: action.error,
        processing: false,
        token: null
      };
    case STRIPE_SET_PROCESSING:
      return { ...state, processing: action.processing };
    case STRIPE_SET_OBJECT:
      return { ...state, complete: false, stripe: action.stripe };
    default:
      return state;
  }
};

export default stripePaymentReducer;
