import { initFlow } from "../../store/actions/FlowActions";
import { USER_LOGOUT } from "../../store/reducers/UserReducer";
import { error as errorToast } from "../../store/actions/ToastAction";

export const handleError = (getState, dispatch, error) => {
  let reThrow = true;
  switch (error.token) {
    case "error_login_required":
      dispatch(initFlow());
      dispatch({ type: USER_LOGOUT });
      break;
    case "invalid_request_token":
      reThrow = false;
      if (typeof window !== "undefined") window.location.reload();
      break;
    default:
      errorToast(error.message);
      break;
  }

  if (reThrow) throw error;
};
