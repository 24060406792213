import React from "react";
import { useTranslation } from "react-i18next";
import Graphic from "resources/images/empty-seats.svg";
import EmptyState from "components/Empty/State/Empty";

const Empty = () => {
  const { t } = useTranslation();
  return (
    <EmptyState
      title={t("events_list_empty_title")}
      textI18Key="events_list_empty_text"
      graphic={Graphic}
    />
  );
};

export default Empty;
