import React, { useEffect, useState } from "react";

// core components
import Grid from "@material-ui/core/Grid";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import { useTranslation } from "react-i18next";
//import styles from "assets/jss/material-kit-react/views/common.js";
import { Link } from "react-router-dom";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { fetchSchedule } from "store/actions/ScheduleAction";
import { connect } from "react-redux";

import moment from "moment";
import Button from "@material-ui/core/Button";
import { FaCalendarAlt } from "react-icons/fa";

import cx from "clsx";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import "./style.scss";
import EmptyEvents from "./EmptyEvents";

//import InputLabel from "@material-ui/core/InputLabel";
//import {Select} from "@material-ui/core";
//import MenuItem from '@material-ui/core/MenuItem';
//import FormControl from "@material-ui/core/FormControl";
import { useParams, useHistory } from "react-router-dom";

import defaultEvent from "assets/img/defaultEvent.jpg";
//import MediaQuery from 'react-responsive';

import { FaChevronCircleLeft } from "react-icons/fa";
import { FaChevronCircleRight } from "react-icons/fa";

export const IMG_VARIATION = "strip&scale_crop=800x600&format=jpeg";

const useStyles = makeStyles(({ spacing, palette }) => {
  const family =
    '-apple-system, BlinkMacSystemFont, "Segoe UI", Montserrat, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"';
  return {
    title: {
      color: "#ffffff"
    },
    card: {
      //display: 'flex',
      minWidth: 288,
      borderRadius: 12,
      boxShadow: "#000 0 0 8px -3px"
      /* '& > *:nth-child(1)': {
        marginRight: spacing(2),
      },
      '& > *:nth-child(2)': {
        flex: 'auto',
      },*/
    },
    avatar: {},
    shortEvent: {
      color: "#666",
      fontSize: 14,
    },
    nameEvent: {
      fontFamily: family,
      fontSize: 16,
      fontWeight: "600",
      marginBottom: 0
    },
    subheader: {
      fontFamily: family,
      fontSize: 14,
      color: palette.grey[600],
      letterSpacing: "1px",
      marginBottom: 4
    },
    value: {
      fontSize: 12,
      color: "#9b9b9c",
      textTransform: "uppercase"
    },
    date: {
      textAlign: "center"
    },
    day: {
      color: "#b4bcbd",
      fontSize: "3em",
      fontWeight: "600"
    },
    month: {
      color: "#b4bcbd",
      fontSize: "1em",
      fontWeight: "600"
    },
    img: {
      width: "100%",
      transition: "all .3s ease",
      //transition: "transform .2s",
      "&:hover, &:focus": {
        transform: "scale(1.1)"
      }
    },
    imgEvent: {
      overflow: "hidden"
    },
    infoPart: {
      //padding: "0 8px",
      textAlign: "center",
      width: "100%"
    },
    bottomDelimiter: {
      borderBottom: "solid 1px #e1e1e1"
    },
    dateFilters: {
      textAlign: "center"
    },
    formControl: {
      marginRight: "12px"
    }
  };
});

const Event = ({
  className,
  name,
  desc,
  img,
  imgRoot,
  imgName,
  day,
  month,
  time,
  idSchedule,
  shortContents
}) => {
  const styles = useStyles();
  const { t } = useTranslation();

  return (
    <Card className={cx(styles.card, className)} elevation={0}>
      <GridContainer justify="center" spacing={2}>
        <GridItem xs={12}>
          <Box
            display={"flex"}
            alignItems={"center"}
            className={styles.imgEvent}
            component={Link}
            to={`/event/${idSchedule}`}
          >
            <img src={img} alt={imgName} className={styles.img} />
          </Box>
        </GridItem>
      </GridContainer>

      {/*  <Box className={styles.date}>
        <h3 className={styles.month}>{month}</h3>
        <p className={styles.day}>{day}</p>
        <Box display={'flex'} alignItems={'center'}>
          <span className={styles.value}> <FaCalendarAlt /> {time}</span>
        </Box>
      </Box>
*/}
      <Grid container justify="center" className={styles.bottomDelimiter}>
        <Grid item xs={12} className={styles.infoPart}>
          <Box>
            <h3 className={styles.nameEvent}>{name}</h3>
            {shortContents.length !== 0 && <p className={styles.shortEvent}>{shortContents}</p>}
            <p>
              {" "}
              <span className={styles.value}>
                {" "}
                <FaCalendarAlt /> {month} {day}, {time}
              </span>{" "}
            </p>
            {/*  <p className={styles.subheader}>{desc}</p> */}
          </Box>
        </Grid>
      </Grid>

      <Grid container justify="center" direction="row">
        <Grid className={styles.infoPart}>
          <Box
            display={"flex"}
            alignItems={"center"}
            className={styles.seeMore}
          >
            <Button
              component={Link}
              to={`/event/${idSchedule}`}
              style={{ width: "100%" }}
            >
              {t("view_details_btn")}
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Card>
  );
};

const EventList = ({ schedule, fetch, paging }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const date = useParams();
  const history = useHistory();

  const [month, setMonth] = useState(date.month);
  const [year, setYear] = useState(date.year);

  /* Used to manage next/prev month */

  const prevDate = moment(year + "-" + month + "-01", "YYYY-MM-DD")
    .subtract(1, "M")
    .format("YYYY/M");
  const nextDate = moment(year + "-" + month + "-01", "YYYY-MM-DD")
    .add(1, "M")
    .format("YYYY/M");

  const prevLink = (
    <span style={{ cursor: "pointer" }} onClick={e => setYear(prevDate)}>
      <FaChevronCircleLeft style={{ margin: "-2px 6px" }} />

      {t("event_date", {
        year: prevDate.substr(0, 4),
        month: prevDate.split("/").pop()
      })}
    </span>
  );

  const nextLink = (
    <span style={{ cursor: "pointer" }} onClick={e => setYear(nextDate)}>
      {t("event_date", {
        year: nextDate.substr(0, 4),
        month: nextDate.split("/").pop()
      })}

      <FaChevronCircleRight style={{ margin: "-2px 6px" }} />
    </span>
  );

  /* END Used to manage next/prev month */

  useEffect(() => {
    if (year.length < 5) {
      history.push("/events/" + year + "/" + month);
      fetch(year, month);
    }

    if (year.length > 4) {
      setYear(year.substr(0, 4));
      setMonth(year.split("/").pop());
      history.push(
        "/events/" + year.substr(0, 4) + "/" + year.split("/").pop()
      );
      fetch(year.substr(0, 4), year.split("/").pop());
    }
  }, [fetch, paging, month, year, history]);

  return (
    <div className={classes.section}>
      <Grid container direction="row" justify="center" alignItems="center">
        <Grid item xs={12} className={classes.dateFilters}>
          <Grid container direction="row" justify="center" alignItems="center">
            <GridItem xs={12} style={{ marginBottom: "12px" }}>
              <span style={{ borderBottom: "solid 1px" }}>
                {" "}
                {t("event_date", { year: year, month: month })}{" "}
              </span>
            </GridItem>
          </Grid>

          <Grid container direction="row" justify="center" alignItems="center">
            <GridItem xs={6} sm={3}>
              {prevLink}
            </GridItem>
            <GridItem xs={6} sm={3}>
              {nextLink}
            </GridItem>
          </Grid>
        </Grid>
      </Grid>

      <Grid container justify="center" direction="row">
        {schedule.length > 0 &&
          schedule.map(s => (
            <Grid
              key={s.Content_Cms_Entry__}
              item
              xs={12}
              md={4}
              style={{ padding: "12px" }}
            >
              <Event
                name={s.Label}
                shortContents={s.Content_Cms_Entry_Data.Short_Contents}
                key={s.Label}
                desc={s.Planner_Event.Location_Url} // define what we want to display there
                img={
                  s.Content_Cms_Entry_Data &&
                  s.Content_Cms_Entry_Data.Drive_Item
                    ? s.Content_Cms_Entry_Data.Drive_Item.Media_Image[
                        IMG_VARIATION
                      ]
                    : defaultEvent
                }
                imgRoot={
                  s.Content_Cms_Entry_Data &&
                  s.Content_Cms_Entry_Data.Drive_Item
                    ? s.Content_Cms_Entry_Data.Drive_Item.Media_Image.Url
                    : defaultEvent
                }
                imgName={
                  s.Content_Cms_Entry_Data &&
                  s.Content_Cms_Entry_Data.Drive_Item
                    ? s.Content_Cms_Entry_Data.Drive_Item.Filename
                    : "event"
                }
                day={moment(parseInt(s.Planner_Event.Event_Date.unixms)).format(
                  "DD"
                )}
                month={moment(
                  parseInt(s.Planner_Event.Event_Date.unixms)
                ).format("MMMM")}
                time={moment(
                  parseInt(s.Planner_Event.Event_Date.unixms)
                ).format("hh:mm A")}
                idSchedule={s.Planner_Schedule__}
              />
            </Grid>
          ))}

        {schedule.length < 1 && (
          <Grid item xs={12} md={4} style={{ padding: "12px" }}>
            {" "}
            <EmptyEvents />{" "}
          </Grid>
        )}
      </Grid>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    schedule: state.schedule.data,
    loading: state.schedule.loading,
    error: state.schedule.error,
    state: state
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetch: (year, month) =>
      dispatch(fetchSchedule(year, month, { image_variation: IMG_VARIATION }))
  };
};

EventList.fetchSchedule = (match, store) =>
  store.dispatch(fetchSchedule(match.params.year, match.params.month));

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EventList);
