import { rest } from "@karpeleslab/klbfw";
import { handleError } from "../../components/utils/apiErrorHandler";
import { VAT_FETCH, VAT_FETCH_DONE } from "../reducers/OrderReducer";

export const getVat = country => {
  return (dispatch, getState) => {
    dispatch({ type: VAT_FETCH });

    const params = {
      Country__: country
    };

    return rest("Order/Vat", "GET", params)
      .then(data => {
        dispatch({ type: VAT_FETCH_DONE, vat: data.data });
        return data.data;
      })
      .catch(err => {
        handleError(getState, dispatch, err);
      });
  };
};
