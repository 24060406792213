import React from "react";
import { Get } from "@karpeleslab/klbfw";
import moment from "moment";
//import { useTranslation } from "react-i18next";

const Html = () => {
  // const { t } = useTranslation();

  return (
    <div id="mail-html">
      <div
        style={{
          color: "#666",
          marginTop: "-6px"
        }}
      >
        {Get("_ticket").Name_Contact}

        {Get("_ticket").Planner_Event.Location_Postal}

        {moment(Get("_ticket").Planner_Event.Event_Date.unix * 1000).format(
          "YYYY-MM-DD HH:mm:ss"
        )}
      </div>
    </div>
  );
};

export default Html;
