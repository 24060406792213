import React from "react";
import { Get } from "@karpeleslab/klbfw";
import { useTranslation } from "react-i18next";

const Text = () => {
  const { t } = useTranslation();

  return (
    <textarea
      id="mail-text"
      defaultValue={
        Get("_ticket").Name_Contact + "\n\r" + t("mail_reserve_desc_txt")
      }
    />
  );
};

export default Text;
