/*eslint-disable*/
import React,{useState} from "react";

// react components for routing our app without refresh
import { Link } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import {useTranslation} from 'react-i18next';
import Hidden from "@material-ui/core/Hidden";
// core components
import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";
import Button from "components/CustomButtons/Button.js";

import {logout} from "store/actions/UserActions";

import styles from "assets/jss/material-kit-react/components/headerLinksStyle.js";

import moment from "moment";
import {connect} from 'react-redux'
import { useParams } from "react-router-dom"
import onClickOutside from 'react-onclickoutside'

import twitter from 'resources/twitter.png';

const useStyles = makeStyles(styles);

function HeaderLinks({props,user,logout}) {
  const classes = useStyles();
  const {t} = useTranslation();

  const handleLogout = () => {
    logout();
};

  const [month, ] = useState(moment().month()+1);
  const [year, ] = useState(moment().year());

  return (
    <List className={classes.list}>
      <ListItem className={classes.listItem}>

        <Button
          color="transparent"
          className={classes.navLink}
          component={Link}
          to={"/"}
          
        >
          {t('menu_home')}
        </Button>
      </ListItem>
      <ListItem className={classes.listItem}>

        <Button
          color="transparent"
          className={classes.navLink}
          component={Link}
          to={`/events/${year}/${month}`}
          
        >
          {t('menu_events')}
        </Button>
      </ListItem>

      <Hidden smUp implementation="js">
        {user.user ?
        <>
          <ListItem className={classes.listItem}>
            <Button
              color="transparent"
              className={classes.navLink}
              component={Link}
              to={"/account"}
              
            >
              {t('menu_account')}
            </Button>
          </ListItem>

          <ListItem className={classes.listItem}>
            <Button
              color="transparent"
              className={classes.navLink}
              component={Link}
              to={"/order_history"}
              
            >
              {t('menu_order_history')}
            </Button>
          </ListItem>

          <ListItem className={classes.listItem}>
            <Button
              color="transparent"
              className={classes.navLink}
              component={Link}
              to={"/login"}
              onClick={handleLogout}
            >
              {t('menu_logout')}
            </Button>
          </ListItem>
          </>
            :
            <ListItem className={classes.listItem}>
            <Button
              color="transparent"
              className={classes.navLink}
              component={Link}
              to={"/login"}
              
            >
              {t('menu_signin')}
            </Button>
          </ListItem>
        }
      </Hidden>


      <Hidden smDown implementation="js">
        {user.user ?
        <ListItem className={classes.listItem}>
          <CustomDropdown
            noLiPadding
            buttonText={t('menu_account')}
            buttonProps={{
              className: classes.navLink,
              color: "transparent"
            }}
            dropdownList={[
              <Link to={"/account"} className={classes.dropdownLink} >
                {t('menu_account')}
              </Link>,
              <Link to={"/order_history"} className={classes.dropdownLink} >
                {t('menu_order_history')}
              </Link>,
              <Link to={"/login"} onClick={handleLogout} className={classes.dropdownLink} >
              {t('menu_logout')}
            </Link>,
            ]}
          />
        </ListItem>
          :
          <ListItem className={classes.listItem}>
          <Button
            color="transparent"
            className={classes.navLink}
            component={Link}
            to={"/login"}
            
          >
            {t('menu_signin')}
          </Button>
        </ListItem>
        }
      </Hidden>

      <ListItem className={classes.listItem}>
        <a
          className={classes.navLink}
          href="https://www.echelle.co.jp/contact"
        >
        {t('menu_contact')}
        </a>
      </ListItem>
    </List>
  );
}


const mapStateToProps = (state) => {
  return {
      user: state.user,
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => dispatch(logout())
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(HeaderLinks);
