const initState = {
  tags: [
    {
      key: "cfyt-ywngft-z73b-fpxd-vesa-jywxnpmy",
      value: "Commercial",
      token: "contact_tag_commercial"
    },
    {
      key: "cfyt-to4in7-ucjj-gjha-tsun-y2ompnye",
      value: "Others",
      token: "contact_tag_others"
    }
  ],
  naka: "sppt-26xhm4-jxtv-h3vn-nwhn-gnsctpza",
  sending: false,
  sent: false,
  error: null
};

export const CONTACT_SENDING = "CONTACT_SENDING";
export const CONTACT_SENT = "CONTACT_SENT";
export const CONTACT_ERROR = "CONTACT_ERROR";
export const CONTACT_RESET = "CONTACT_RESET";

const contactReducer = (state = initState, action) => {
  switch (action.type) {
    case CONTACT_SENDING:
      return { ...state, sending: true, error: null };
    case CONTACT_SENT:
      return { ...state, sent: true, sending: false, error: null };
    case CONTACT_ERROR:
      return { ...state, sent: false, sending: false, error: action.error };
    case CONTACT_RESET:
      return { ...state, sent: false, sending: false, error: null };
    default:
      return state;
  }
};

export default contactReducer;
