import React from "react";
import { Get } from "@karpeleslab/klbfw";
import { Trans } from "react-i18next";

const Html = () => {
  return (
    <div id="mail-html">
      <div style={{ color: "#666", marginTop: "-6px" }}>
        <div style={{ textAlign: "center" }}>
          <img
            src="https://live.echelle.co.jp/img/header_mailing.png"
            alt="Echelle"
          />
        </div>
        <div
          style={{
            textAlign: "center",
            width: "30%",
            marginLeft: "auto",
            marginRight: "auto",
            marginBottom: "20px"
          }}
        >
          <div style={{ margin: "auto", textAlign: "left" }}>
            <p>{Get("_user").Profile.Display_Name}</p>
            <p>
              <Trans i18nKey="mail_payment_invalidate_desc">
                sample
                <br />
              </Trans>
            </p>

            <p>Echelle</p>
            <p>
              <a href="https://live.echelle.co.jp/">https://live.echelle.co.jp/</a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Html;
