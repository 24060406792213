/*eslint-disable*/
import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";
// material-ui core components
import { List, ListItem } from "@material-ui/core";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import styles from "assets/jss/material-kit-react/components/footerStyle.js";
import moment from "moment";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles(styles);

export default function Footer(props) {
  const classes = useStyles();
  const { whiteFont } = props;
  const { t } = useTranslation();
  const footerClasses = classNames({
    [classes.footer]: true,
    [classes.footerWhiteFont]: whiteFont
  });
  const aClasses = classNames({
    [classes.a]: true,
    [classes.footerWhiteFont]: whiteFont
  });
  return (
    <footer className={footerClasses} style={{
      padding: 0,
      position: "relative",
      backgroundSize: "cover",
      backgroundColor: "#fbfcfe",
      backgroundRepeat: "no-repeat",
      backgroundColor: "transparent",
      color: "#ffffff",
      "&:hover":{
        color: "#05d1d6"
      }
    }}>
      <div className={classes.container}>
        <div className={classes.left}>
          <List className={classes.list}>
            <ListItem className={classes.inlineBlock}>
              <a
                href="https://www.echelle.co.jp/page/terms_of_service"
                className={classes.block}
              >
                {t("footer_terms_conditions")}
              </a>
            </ListItem>

            <ListItem className={classes.inlineBlock}>
              <a
                href="https://www.echelle.co.jp/page/privacy_policy"
                className={classes.block}
              >
                {t("footer_privacy_policy")}
              </a>
            </ListItem>

            <ListItem className={classes.inlineBlock}>
              <a
                href="https://www.echelle.co.jp/page/scta"
                className={classes.block}
              >
                {t("footer_scta")}
              </a>
            </ListItem>

          </List>
        </div>
        <div className={classes.right}>
          {t("footer_copyright", { year: moment().year() })}
        </div>
      </div>
    </footer>
  );
}

Footer.propTypes = {
  whiteFont: PropTypes.bool
};
