import React from "react";
import { Route, Switch } from "react-router-dom";
import { getMode } from "@karpeleslab/klbfw";
import Header from "./Header";
import Footer from "components/Footer/Footer.js";
import routes from "../../router/routeConfig";
import LoginRequired from "../Route/LoginRequired";
import ScrollToTop from "../Route/ScrollToTop";
import Toasts from "components/common/feedback/Toasts";
import { theme } from "./Theme";
import { ThemeProvider } from "@material-ui/styles";
import NoSsr from "@material-ui/core/NoSsr";
import classNames from "classnames";
import Slider from "./Slider";
import { useLocation } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-kit-react/views/common.js";
require("typeface-montserrat");
require("typeface-architects-daughter");

const useStyles = makeStyles(styles);
export default () => {
  const page = useLocation();

  const mode = getMode();
  const classes = useStyles();
  const buildRoute = (idx, route) => {
    if ("mode" in route) {
      if (Array.isArray(route.mode)) {
        if (!route.mode.includes(mode)) return null;
      } else if (mode !== route.mode) return null;
    }

    const C = "components" in route ? route.components : route.component;
    const Component = "components" in route ? C : <C />;

    delete route.component;

    if ("loginRequired" in route && route.loginRequired === true) {
      return (
        <Route key={idx} {...route}>
          <LoginRequired>{Component}</LoginRequired>
        </Route>
      );
    }

    return (
      <Route key={idx} {...route}>
        {Component}
      </Route>
    );
  };

  const buildRoutes = () => {
    return (
      <Switch>
        {routes.map((route, idx) => {
          switch (route.type) {
            case "route":
            default:
              return buildRoute(idx, { ...route }); // Copy it to not alter the original
          }
        })}
      </Switch>
    );
  };

  const addLayout =
    mode !== "ssr-open" && mode !== "client-open" && mode !== "ssr-mail";
  if (!addLayout)
    return <ThemeProvider theme={theme}>{buildRoutes()}</ThemeProvider>;

  return (
    <ThemeProvider theme={theme}>
      <div
        className={
          page.pathname === "/" || page.pathname.indexOf('live') === 1
            ? classNames(classes.filter, classes.nobackground)
            : classNames(classes.filter, classes.background)
        }
      >
        <ScrollToTop />
        <Toasts />
        <div style={{ minHeight: "calc(100vh - 70px)" }}>
          <Header />

          {page.pathname === "/" && (
            <NoSsr>
              <Slider />
            </NoSsr>
          )}
          <div className={classNames(classes.main, classes.mainRaised)}>
            {page.pathname === "/" && <div>{buildRoutes()}</div>}

            {page.pathname !== "/" && page.pathname.indexOf('live') !== 1 && (
              <div className={classes.container}>{buildRoutes()}</div>
            )}

            {
              page.pathname.indexOf('live') === 1 && (
                <div className="aaaaaaa">{buildRoutes()}</div>
              ) 
            }

          </div>
        </div>

        <div style={{ height: "50px", background: "white" }}>
          <Footer />
        </div>
      </div>
    </ThemeProvider>
  );
};
