import React from "react";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import makeStyles from "@material-ui/core/styles/makeStyles";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

const useStylesT = makeStyles(theme => ({
  title: {
    color: "#05d1d6",
    textDecoration: "none",
    position: "relative",
    fontWeight: "bold",
    "&::after": {
      content: '""',
      position: "absolute",
      margin: "0 auto",
      left: "0",
      right: "0",
      width: "100%",
      height: "2px",
      bottom: "-16px",
      background: "transparent"
    }
  },
  desc: {
    marginTop: "35px",
    color: "#ACACAC",
    fontWeight: "normal",
    fontSize: "x-large"
  }
}));

const BoxedText = ({ title, description, disabledGutter = false }) => {
  const classesT = useStylesT();

  return (
    <GridContainer justify="center">
      <GridItem xs={12} md={6}>
        <Box
          m={disabledGutter ? 0 : 12}
          p={4}
          bgcolor="#f4f4f4"
          textAlign="center"
          style={{ marginBottom: "24px" }}
        >
          <Typography variant="h3" className={classesT.title} component="span">
            {title}
          </Typography>

          <Typography variant="h6" className={classesT.desc} component="p">
            {description}
          </Typography>
        </Box>
      </GridItem>
    </GridContainer>
  );
};

export default BoxedText;
