import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import styles from "assets/jss/material-kit-react/components/typographyStyle.js";
import OrderHistoryTable from "./Table";

const useStyles = makeStyles(styles);

export default function History() {
  const classes = useStyles();

  return (
    <div className={classes.section}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12}>
          <OrderHistoryTable />
        </GridItem>
      </GridContainer>
    </div>
  );
}
