import React from "react";
import Grid from "@material-ui/core/Grid";
import { CircularProgress } from "@material-ui/core";

const Loading = props => {
  return (
    <Grid
      container
      direction={props.direction ?? "row"}
      justify={props.justify ?? "center"}
      alignItems={props.alignItems ?? "center"}
    >
      <Grid item>
        <CircularProgress className={props.className === void 0 ? '' : props.className} {...props} />
      </Grid>
    </Grid>
  );
};

export default Loading;
