import React from "react";
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";

const dashboardRoutes = [];

export default props => {
  const { ...rest } = props;

  return (
    <Header
      color="white"
      routes={dashboardRoutes}
      brand=""
      rightLinks={<HeaderLinks />}
      fixed
      /* changeColorOnScroll={{
            height: 400,
            color: "primary"
            }}*/
      {...rest}
    />
  );
};
