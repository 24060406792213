import { primaryColor, container } from "assets/jss/material-kit-react.js";

const orderPageStyle = {
  container: {
    zIndex: "12",
    color: "#fbfcfe",
    ...container
  },
  main: {
    background: "#fbfcfe",
    position: "relative",
    zIndex: "3"
  },
  mainRaised: {
    padding: "32px",
    margin: "-460px 30px 0px",
    borderRadius: "6px",
    boxShadow:
      "0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)"
  },
  titleStep: {
    color: primaryColor,
    marginLeft: "12px",
    marginTop: "-20px"
  },
  plans: {
    marginBottom: "18px"
  },
  plan: {
    background: "white",
    cursor: "pointer",
    padding: "16px",
    border: "2px solid #afe170",
    color: "#000000",
    height: "105px"
  },
  planNonSelected: {
    background: "white",
    cursor: "pointer",
    padding: "16px",
    border: "2px solid #afe17070",
    color: "#000000",
    height: "105px"
  },
  planName: {
    color: primaryColor
  },
  price: {
    color: primaryColor
  },
  infoPlan: {
    color: primaryColor
  },
  paymentMethod: {
    cursor: "pointer",
    border: "2px solid #afe170",
    color: "#000000"
  },
  titleFeatures: {
    color: primaryColor
  },
  titleFeature: {
    color: primaryColor
  },
  descFeature: {
    color: primaryColor
  },
  formControl: {
    width: "100%"
  }
};

export default orderPageStyle;
