import React, { Component } from "react";
import { connect } from "react-redux";
import { Tooltip } from "@material-ui/core";
// image
import defaultAvatar from "../../resources/images/default-avatar.svg";

import {
  AvatarBtn,
  AvatarBtnsWrapper,
  AvatarBtnWrapper,
  AvatarImages,
  AvatarWrapper,
  AvatarEditor as StyledAvatarEditor,
  AvatarEditorRange,
  AvatarEditorImages,
  AvatarEditorImagesInner
} from "./styles";
import { withTranslation } from "react-i18next";

class AvatarEditor extends Component {
  state = {
    imgPath: null,
    width: 170,
    height: 170,
    scale: 1,
    preview: {
      img: null
    }
  };

  handleSetImage = () => {
    this.editor.getImageScaledToCanvas().toBlob(blob => {
      blob["name"] = "avatar.png";
      this.props.setAvatarImage(this.props.field.name, blob);
    });
  };

  handleScale = e => {
    const scale = parseFloat(e.target.value);
    this.setState({ scale });
    this.handleSetImage();
  };

  setEditorRef = editor => {
    if (editor) {
      this.editor = editor;
      setTimeout(() => {
        this.handleSetImage();
      }, 500);
    }
  };

  handleNewImage = e => {
    this.setState({ imgPath: e.target.files[0] });
  };

  handleRemove = () => {
    this.setState({
      imgPath: null,
      scale: 1
    });
    this.croppieFileInput.value = "";
    this.props.setAvatarImage(this.props.field.name, this.props.defaultAvatar);
  };

  AvatarImage = props => {
    const { t } = this.props;
    if (this.state.imgPath !== null) {
      return (
        <AvatarEditorImages>
          <AvatarEditorImagesInner>
            <StyledAvatarEditor
              ref={this.setEditorRef}
              image={this.state.imgPath}
              width={this.state.width}
              height={this.state.height}
              borderRadius={100}
              border={0}
              scale={this.state.scale}
              rotate={0}
              onPositionChange={this.handleSetImage}
            />
          </AvatarEditorImagesInner>
          <AvatarEditorRange
            name="scale"
            type="range"
            onChange={this.handleScale}
            min="1"
            max="2"
            step="0.01"
            defaultValue="1"
          />
        </AvatarEditorImages>
      );
    } else {
      return (
        <AvatarImages>
          <img
            src={
              this.props.defaultAvatar
                ? this.props.defaultAvatar
                : defaultAvatar
            }
            alt={t("default_avatar")}
          />
        </AvatarImages>
      );
    }
  };

  render() {
    const { t } = this.props;
    return (
      <AvatarWrapper>
        <div>
          <div>
            <this.AvatarImage />
          </div>
        </div>
        <AvatarBtnsWrapper>
          <Tooltip
            title={t("profile_upload_image_tooltip")}
            placement="bottom"
            arrow
          >
            <AvatarBtnWrapper>
              <AvatarBtn className="upload">
                {t("profile_upload_image")}
                <input
                  id={this.props.fieldId}
                  name={this.props.field.name}
                  ref={ref => (this.croppieFileInput = ref)}
                  type="file"
                  accept="image/png, image/jpeg, image/jpg, image/svg"
                  onChange={this.handleNewImage}
                  required={this.props.required}
                />
              </AvatarBtn>
            </AvatarBtnWrapper>
          </Tooltip>
          <Tooltip
            title={t("profile_delete_image_tooltip")}
            placement="bottom"
            arrow
          >
            <AvatarBtnWrapper>
              <AvatarBtn onClick={this.handleRemove}>
                {t("profile_delete_image")}
              </AvatarBtn>
            </AvatarBtnWrapper>
          </Tooltip>
        </AvatarBtnsWrapper>
      </AvatarWrapper>
    );
  }
}

const mapStateToProps = state => {
  return {
    avatar: state.avatar
  };
};

const mapDispatchToProps = dispatch => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(AvatarEditor));
