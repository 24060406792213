import React from "react";
import Helmet from "components/instances/instance/Helmet/Helmet";
import Grid from "@material-ui/core/Grid";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components

import styles from "assets/jss/material-kit-react/views/common.js";
import { useTranslation } from "react-i18next";
// Sections for this page
import Reserve from "./Sections/Reserve";

const useStyles = makeStyles(styles);

export default function ReservePage(props) {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <div className={classes.section}>
      <Helmet title={t("helmet_echelle_reservation")} />
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="center"
        style={{ background: "#5B9797", marginBottom: "24px" }}
      >
        <h2 className={classes.titlePage}>{t("reserve_title")}</h2>
      </Grid>

      <Reserve />
    </div>
  );
}
