import {
  RESERVE_EVENT,
  RESERVE_EVENT_DONE,
  RESERVE_EVENT_ERROR,
  TICKET_NB_SELECT,
  EVENT_SELECT,
  EVENT_FETCH,
  EVENT_FETCH_DONE,
  RESERVED_SEAT_BY_EVENT,
  RESERVED_SEAT_BY_EVENT_DONE,
  RESERVED_SEAT_BY_EVENT_UPDATE,
  RESERVED_SEATS,
  RESERVED_SEATS_DONE,
  HISTORY_SET_PAGING
} from "../reducers/ReserveReducer";
import moment from "moment";

import { rest } from "@karpeleslab/klbfw";

import { handleError } from "../../components/utils/apiErrorHandler";

export const fetchReservedEvent = (schedule, variationImg) => {
  return (dispatch, getState) => {
    dispatch({ type: EVENT_FETCH });

    const params = {
      image_variation: variationImg
    };

    return rest("Planner/Schedule/" + schedule, "GET", params)
      .then(data => {
        dispatch({ type: EVENT_FETCH_DONE, schedule: data.data });
        return data.data;
      })
      .catch(err => {
        handleError(getState, dispatch, err);
      });
  };
};

export const reserveEvent = (
  name,
  furigana,
  tel,
  answers,
  remarks,
  seat,
  cc_token
) => {
  return (dispatch, getState) => {
    dispatch({ type: RESERVE_EVENT });

    const params = {
      name: name,
      furigana: furigana,
      tel: tel,
      answers: answers,
      remarks: remarks,
      seat: seat,
      method: "Stripe",
      cc_token: cc_token
    };

    return rest(
      "Planner/Event/" + getState().reserve.data.Planner_Event__ + ":reserve",
      "POST",
      params
    )
      .then(data => {
        dispatch({ type: RESERVE_EVENT_DONE, event: data.data });
        return data.data;
      })
      .catch(err => {
        dispatch({ type: RESERVE_EVENT_ERROR, error: err });
        handleError(getState, dispatch, err);
      });
  };
};

export const selectNbTicket = nbTicket => {
  return (dispatch, getState) => {
    dispatch({ type: TICKET_NB_SELECT, nbTicket: nbTicket });
  };
};

export const selectEvent = schedule => {
  return (dispatch, getState) => {
    dispatch({ type: EVENT_SELECT, schedule: schedule });
    // dispatch(fetchReservedEvent(schedule))
  };
};

export const reservedSeatByEvent = id => {
  return (dispatch, getState) => {
    dispatch({ type: RESERVED_SEAT_BY_EVENT });

    return rest("Planner/Event/" + id + "/Seat/Reserve", "GET")
      .then(data => {
        dispatch({
          type: RESERVED_SEAT_BY_EVENT_DONE,
          seats: data.data,
          time: moment()
        });
        return data.data;
      })
      .catch(err => {
        handleError(getState, dispatch, err);
      });
  };
};

export const reservedSeatByEventDelay = id => {
  return (dispatch, getState) => {
    return rest("Planner/Event/" + id + "/Seat/Reserve", "GET")
      .then(data => {
        dispatch({
          type: RESERVED_SEAT_BY_EVENT_UPDATE,
          seats: data.data,
          time: moment()
        });
      })
      .catch(err => {
        handleError(getState, dispatch, err);
      });
  };
};

export const reservedSeats = () => {
  return (dispatch, getState) => {
    dispatch({ type: RESERVED_SEATS });

    const params = {
      ...getState().reserve.historyPaging
    };

    return rest("Planner/Event/Seat/Reserve", "GET", params)
      .then(data => {
        dispatch({
          type: RESERVED_SEATS_DONE,
          allReservedSeats: data.data,
          paging: data.paging
        });
        return data.data;
      })
      .catch(err => {
        handleError(getState, dispatch, err);
      });
  };
};

export const setTicketsReservedHistoryPaging = newPaging => {
  return (dispatch, getState) => {
    dispatch({
      type: HISTORY_SET_PAGING,
      paging: { ...getState().reserve.historyPaging, ...newPaging }
    });
  };
};

export const reserveCart = (name, furigana, phone, seat, answers) => {
  let params = {
    name: name,
    tel: phone,
    seat: seat
  };

  if (furigana.length > 1) {
    params.furigana = furigana;
  }

  if (Object.keys(answers).length !== 0) {
    params.answers = answers;
  }

  return (dispatch, getState) => {
    rest("Catalog/Cart/@:reset", "POST");

    return rest(
      `Planner/Event/${getState().reserve.data.Planner_Event__}:reserveCart`,
      "POST",
      params
    )
      .then(data => {
        return data;
      })
      .catch(err => {
        handleError(getState, dispatch, err);
        throw err;
      });
  };
};
