import React, { Fragment, useEffect, useState, useCallback } from "react";
// @material-ui/core components
import TextField from "@material-ui/core/TextField";

// core components
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  selectEvent,
  selectNbTicket,
  fetchReservedEvent,
  reserveCart
} from "store/actions/ReserveAction";
import { getVat } from "store/actions/OrderAction";
import { rest, getLocale } from "@karpeleslab/klbfw";
import Button from "@material-ui/core/Button";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Grid from "@material-ui/core/Grid";
import moment from "moment";
import { useHistory, useParams } from "react-router-dom";
import { useRest } from "@karpeleslab/react-klbfw-hooks";

import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import _ from "lodash";

import Countries from "components/Countries/Countries";
import defaultEventImg from "assets/img/defaultEvent.jpg";

export const IMG_VARIATION = "strip&scale_crop=380x120&format=jpeg";


const useStyles = makeStyles(theme => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
      width: "25ch"
    }
  },
  textField: {
    color: "#000000!important"
  },
  container: {
    border: "solid 1px #05d1d6",
    padding: "12px",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center"
    }
  },
  img: {
    border: "solid 1px #05d1d6"
  },
  infoText: {
    textAlign: "center",
    fontWeight: "bold",
    color: "#05d1d6"
  },
  infoCart: {
    fontWeight: "bold",
    minWidth: "75px",
    display: "inline-block",
    textAlign: "right"
  },
  detailsCart: {
    textAlign: "center"
  }
}));

const Reservation = props => {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();

  const { fetch, fetchE } = props;
  const [lName, setLName] = useState("");
  const [fName, setFName] = useState("");
  const [lNameKana, setLNameKana] = useState("");
  const [fNameKana, setFNameKana] = useState("");
  const [phone, setPhone] = useState("");
  const [orderBtnEnabled, setOrderBtnEnabled] = useState(false);
  const [useLoading, setUseLoading] = useState(false);
  const [answers, setAnswers] = useState({});

  const [country, setCountry] = useState("");
  const {
    selectNbTicket,
    selected,
    reserve,
    loading,
    getVat,
    reserveCart
  } = props;

  let eventId = useParams().event;
  let nbTicket = useParams().qty;

  const [geoVal] = useRest("ThirdParty/Geoip:lookup");

  useEffect(() => {
    fetch(eventId);
    selectNbTicket(nbTicket);
    fetchE(eventId);
  }, [fetch, eventId, selectNbTicket, nbTicket, fetchE]);

  useEffect(() => {
    if (geoVal !== null) {
      setCountry(geoVal.data.country.iso_code);
      getVat(geoVal.data.country.iso_code);
    }
  }, [geoVal, getVat]);

  useEffect(() => {
    if (
      selected !== void 0 &&
      selected.length !== 0 &&
      selected.Planner_Event.Questions.length !== 0 &&
      Object.keys(answers).length === 0
    ) {
      let defaulsAnsswer = {};

      selected.Planner_Event.Questions.map(data => {
        return (defaulsAnsswer[data.Code] = "");
      });

      setAnswers({
        ...answers,
        ...defaulsAnsswer
      });
    }
  }, [selected, answers, setAnswers]);

  const questionCheck = useCallback(() => {
    if (Object.keys(answers).length === 0) return true;

    let questionArray = [];

    _.map(answers, data => {
      questionArray.push(!data.trim());
    });

    if (questionArray.indexOf(true) === -1) {
      return true;
    } else {
      return false;
    }
  }, [answers]);

  useEffect(() => {
    if (getLocale() === "ja-JP") {
      if (
        !questionCheck() ||
        !lName.trim() ||
        !fName.trim() ||
        !lNameKana.trim() ||
        !fNameKana.trim() ||
        !country.trim() ||
        !phone.trim()
      ) {
        setOrderBtnEnabled(false);
        return;
      }
    } else {
      if (
        !questionCheck() ||
        !lName.trim() ||
        !fName.trim() ||
        !country.trim() ||
        !phone.trim()
      ) {
        setOrderBtnEnabled(false);
        return;
      }
    }

    setOrderBtnEnabled(true);
  }, [
    fName,
    lName,
    fNameKana,
    lNameKana,
    phone,
    setOrderBtnEnabled,
    country,
    geoVal,
    questionCheck
  ]);

  const handleReserve = () => {
    setUseLoading(true);

    const formVal = {
      First_Name: fName,
      Last_Name: lName,
      Country__: country,
      Contact_Phone: phone
    };

    let name = `${lName} ${fName}`;
    let nameF = `${lNameKana} ${fNameKana}`;
    if (getLocale() !== "ja-JP") {
      name = `${fName} ${lName}`;
      nameF = `(none)`;
    }

    reserveCart(
      name,
      nameF,
      phone,
      selected.Planner_Event.Seats[0].Planner_Event_Seat__,
      answers
    )
      .then(data => {
        rest("User/@/Location", "POST", formVal).then(data => {
          rest("User/@:setDefaultLocation", "POST", {
            User_Location__: data.data.User_Location__,
            type: "Billing"
          }).then(() => {
            rest("Catalog/Cart/@:createOrder", "POST")
              .then(data => {
                setUseLoading(false);
                history.push("/order/" + data.data.Order__);
              })
              .catch(data => {
                setUseLoading(false);
              });
          });
        });
      })
      .catch(err => {
        setUseLoading(false);
      });
  };

  const setAnserAray = (data, value) => {
    let answerArray = {};

    answerArray[data.Code] = value;

    setAnswers({
      ...answers,
      ...answerArray
    });
  };

  const buildStringQue = (data, idx) => {
    return (
      <Grid key={idx} item xs={12}>
        <TextField
          className={classes.textField}
          label={data.Question}
          variant="outlined"
          fullWidth
          required
          onChange={e => setAnserAray(data, e.target.value)}
          disabled={useLoading}
        />
      </Grid>
    );
  };

  const buildSelectQue = (data, idx) => {
    return (
      <Grid key={idx} item xs={12}>
        <FormControl component="label">
          <FormLabel component="label">{data.Question} *</FormLabel>
          <RadioGroup
            style={{
              width: "auto",
              height: "auto",
              display: "flex",
              flexWrap: "nowrap",
              flexDirection: "row"
            }}
            name={data.Code}
            onChange={e => setAnserAray(data, e.target.value)}
            disabled={useLoading}
          >
            {data.Options.map((oprion, idxs) => {
              return (
                <FormControlLabel
                  key={idxs}
                  value={oprion}
                  control={<Radio />}
                  label={oprion}
                />
              );
            })}
          </RadioGroup>
        </FormControl>
      </Grid>
    );
  };

  const buildQuestion = () => {
    if (selected.Planner_Event.Questions.length === 0) return null;

    return selected.Planner_Event.Questions.map((data, idx) => {
      let content;

      switch (data.Type) {
        case "string":
          content = buildStringQue(data, idx);
          break;
        case "select":
          content = buildSelectQue(data, idx);
          break;
        default:
          content = null;
      }
      return content;
    });
  };

  const buildQuestionGroup = () => {
    if (selected.Planner_Event.Questions.length === 0) return null;

    return (
      <Grid item xs={12}>
        <div className={classes.container}>
          <Grid
            spacing={1}
            container
            direction="row"
            justify="center"
            alignItems="center"
          >
            <Grid item xs={12}>
              <p className={classes.infoText}>{t("question_text")}</p>
            </Grid>
            {buildQuestion()}
          </Grid>
        </div>
      </Grid>
    );
  };

  const buildLangSort = () => {
    if (getLocale() === "ja-JP") {
      return (
        <>
          <Grid item xs={6}>
            <TextField
              className={classes.textField}
              required
              label={t("Last_Name_lbl")}
              variant="outlined"
              fullWidth
              value={lName}
              onChange={e => setLName(e.target.value)}
              disabled={useLoading}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              className={classes.textField}
              required
              label={t("First_Name_lbl")}
              variant="outlined"
              fullWidth
              value={fName}
              onChange={e => setFName(e.target.value)}
              disabled={useLoading}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              className={classes.textField}
              required
              label={t("Last_NameKana_lbl")}
              variant="outlined"
              fullWidth
              value={lNameKana}
              onChange={e => setLNameKana(e.target.value)}
              disabled={useLoading}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              className={classes.textField}
              required
              label={t("First_NameKana_lbl")}
              variant="outlined"
              fullWidth
              value={fNameKana}
              onChange={e => setFNameKana(e.target.value)}
              disabled={useLoading}
            />
          </Grid>
        </>
      );
    } else {
      return (
        <>
          <Grid item xs={6}>
            <TextField
              className={classes.textField}
              required
              label={t("First_Name_lbl")}
              variant="outlined"
              fullWidth
              value={fName}
              onChange={e => setFName(e.target.value)}
              disabled={useLoading}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              className={classes.textField}
              required
              label={t("Last_Name_lbl")}
              variant="outlined"
              fullWidth
              value={lName}
              onChange={e => setLName(e.target.value)}
              disabled={useLoading}
            />
          </Grid>
        </>
      );
    }
  };


  const buildImg = () => {

    if (selected.Content_Cms_Entry_Data.Drive_Item) {
      return (
        <img
          className={classes.img}
          src={
            selected.Content_Cms_Entry_Data.Drive_Item.Media_Image[
              IMG_VARIATION
            ]
          }
          width="100%"
          alt={selected.Label}
        />
      )
    } else {
      return (
        <img
          className={classes.img}
          src={defaultEventImg}
          width="100%"
          alt={selected.Label}
        />
      )
    }

  }

  return (
    <div className={classes.section}>
      {!loading && selected && (
        <Grid
          container
          spacing={3}
          className={classes.root}
          direction="row"
          justify="center"
          alignItems="center"
        >
          <Grid item xs={12}>
            <Grid container spacing={1} className={classes.container}>
              <Grid item xs={12} md={4}>
                {buildImg()}
              </Grid>

              <Grid item xs={12} sm={6} md={2}>
                <h4 style={{ fontWeight: "bold" }}>{t("reserve_name")}</h4>
                <h5>{selected && selected.Label}</h5>
              </Grid>
              <Grid item xs={12} sm={6} md={2}>
                <h4 style={{ fontWeight: "bold" }}>
                  {t("reserve_event_starts")}
                </h4>
                <h5>
                  {selected &&
                    moment(
                      parseInt(selected.Planner_Event.Event_Date.unixms)
                    ).format("YYYY/MM/DD HH:mm")}
                </h5>
              </Grid>
              <Grid item xs={12} sm={6} md={2}>
                <h4 style={{ fontWeight: "bold" }}>{t("reserve_qty")}</h4>
                <h5>{reserve && reserve.nbTicket}</h5>
              </Grid>
              <Grid item xs={12} sm={6} md={2}>
                <h4 style={{ fontWeight: "bold" }}>
                  {t("reserve_unit_price")}
                </h4>
                <h5>
                  {selected &&
                    selected.Planner_Event.Seats[0].Price.display_short}
                </h5>
              </Grid>
            </Grid>
          </Grid>

          <Fragment>
            <Grid item xs={12}>
              <Grid container spacing={1}>
                <Grid item xs={12} md={12}>
                  <div className={classes.container}>
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <p className={classes.infoText}>
                          {t("informations_required_validate_order_p")}
                        </p>
                      </Grid>
                      {buildLangSort()}
                      <Grid item xs={12}>
                        <TextField
                          className={classes.textField}
                          required
                          label={t("phone_lbl")}
                          variant="outlined"
                          fullWidth
                          value={phone}
                          onChange={e =>
                            setPhone(e.target.value.replace("+", ""))
                          }
                          disabled={useLoading}
                          placeholder={"00000000000"}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Countries onChange={setCountry} value={country} />
                      </Grid>
                    </Grid>
                  </div>
                </Grid>

                {buildQuestionGroup()}

                <Grid item xs={12}>
                  <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="center"
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleReserve}
                      disabled={!orderBtnEnabled || useLoading}
                    >
                      {t("order_btn")}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Fragment>
        </Grid>
      )}
    </div>
  );
};

const mapStateToProps = state => {
  return {
    reserve: state.reserve,
    selected: state.reserve.data,
    loading: state.reserve.loading,
    error: state.reserve.error,
    complete: state.stripe.complete
  };
};

const mapDispatchToProps = dispatch => {
  return {
    reserveCart: (name, furigana = "(none)", phone, seat, answers = []) =>
      dispatch(reserveCart(name, furigana, phone, seat, answers)),
    fetchE: eventId =>
      dispatch(fetchReservedEvent(eventId, { image_variation: IMG_VARIATION })),
    fetch: eventId => dispatch(selectEvent(eventId)),
    selectNbTicket: nbTicket => dispatch(selectNbTicket(nbTicket)),
    getVat: country => dispatch(getVat(country))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Reservation);
