import { combineReducers } from "redux";

import FlowReducer from "./FlowReducer";
import UserReducer from "./UserReducer";
import LanguageReducer from "./LanguageReducer";
import ToastReducer from "./ToastReducer";
import cmsReducer from "./CmsReducer";
import UserBillingReducer from "./UserBillingReducer";
import StripePaymentReducer from "./StripePaymentReducer";
import ContactReducer from "./ContactReducer";
import OrderReducer from "./OrderReducer";
import FaqReducer from "./FaqReducer";
import CommonReducer from "./CommonReducer";
import OrderHistoryReducer from "./OrderHistoryReducer";
import ScheduleReducer from "./ScheduleReducer";
import ReserveReducer from "./ReserveReducer";
import CountriesReducer from "./CountriesReducer";

const rootReducer = combineReducers({
  common: CommonReducer,
  countries: CountriesReducer,
  user: UserReducer,
  flow: FlowReducer,
  language: LanguageReducer,
  toast: ToastReducer,
  cms: cmsReducer,
  userBilling: UserBillingReducer,
  stripe: StripePaymentReducer,
  contact: ContactReducer,
  order: OrderReducer,
  faq: FaqReducer,
  orderHistory: OrderHistoryReducer,
  schedule: ScheduleReducer,
  reserve: ReserveReducer
});

export default rootReducer;
