import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import { Trans } from "react-i18next";

const Empty = ({ title, text, textI18Key, action = null, graphic = null }) => {
  return (
    <Grid container spacing={3} direction="row" justify="center">
      {graphic && (
        <Grid item xs={4} md={4}>
          <img src={graphic} alt="artist" width="100%" />
        </Grid>
      )}

      <Grid item xs={12} md={graphic ? 8 : 12}>
        <Grid container spacing={3} direction="column" justify="center">
          <Grid item xs={12}>
            <Typography variant="h5" color="textPrimary" component="h5">
              {title}
            </Typography>
          </Grid>

          <Grid item xs={12}>
            {!textI18Key && text}
            {textI18Key && (
              <Trans i18nKey={textI18Key}>
                sample <br />
                <strong>sample</strong>
              </Trans>
            )}
          </Grid>
          {action && (
            <Grid item xs={12}>
              <Grid container justify="center">
                <Button
                  component={Link}
                  variant="contained"
                  color="primary"
                  {...action}
                >
                  {action.title}
                </Button>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Empty;
