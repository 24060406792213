import React from "react";
import moment from "moment";
import classNames from "classnames";

// icon
import { FaRegCalendarAlt, FaRegClock } from "react-icons/fa";
import { BsChatDotsFill } from "react-icons/bs";

// style
import style from "Scss/live.module.scss";

// img
import defaultEvent from "assets/img/defaultEvent.jpg";
import 'Scss/live.scss';

const Info = (props) => {
	const { selected, chatStatus, setChatStatus, disabledChat } = props;
	const IMG_VARIATION = "strip&scale=470x600&format=jpeg";

	const timeFormat = (timeValue, type) => {
		const intValue = parseInt(timeValue)
		return moment(intValue).format(type)
	}

  return (
    <div
			className={classNames(style['live-info-inner'], {
				[style['live-info-inner-chat']]: !chatStatus,
			})}
		>
			<div
				className={style['live-info-head']}
			>
				<ul
					className={style['live-info-head-time']}
				>
					<li>
						<span><FaRegCalendarAlt /></span>{timeFormat(selected.Planner_Event.Event_Date.unixms, "LL")}
					</li>
					<li>
						<span><FaRegClock /></span>{timeFormat(selected.Planner_Event.Event_Date.unixms, "LT")}
					</li>
				</ul>
				<h2
					className={style['live-info-head-title']}
				>{selected.Label}</h2>
				{
					selected.Content_Cms_Entry_Data.Short_Contents && (
					<p				
						className={style['live-info-head-desc']}
					>{selected.Content_Cms_Entry_Data.Short_Contents}</p>
					)
				}
				{!disabledChat &&
					<button
						className={classNames(style['live-info-head-btn'], {
							[style['live-info-head-btn-active']]: !chatStatus
						})}
						onClick={() => {setChatStatus(true)}}
					>
						<BsChatDotsFill />
					</button>
				}
			</div>
			
			<div
				className={style['live-info-body']}
			>
				<figure
					className={style['live-info-body-img']}
				>
					{selected.Content_Cms_Entry_Data.Drive_Item ? (
						<img
							src={
								selected.Content_Cms_Entry_Data.Drive_Item.Media_Image[IMG_VARIATION]
							}
							style={{
								width: "100%",
								border: "solid 1px #05d1d6"
							}}
							alt="Event"
						/>
					) : (
						<img
							src={defaultEvent}
							style={{ width: "100%" }}
							alt="Event"
						/>
					)}
				</figure>
				<div
					className='cms-content'
					dangerouslySetInnerHTML={{
						__html:
							selected.Content_Cms_Entry_Data &&
							selected.Content_Cms_Entry_Data.Contents
					}}
				/>
			</div>
		</div>
  );
};

export default Info;
