const initState = {
  error: null,
  nbTicket: 1,
  loading: true,
  schedule: null,
  data: [],
  seats: [],
  allReservedSeats: [],
  historyPagingCount: 0,
  historyPaging: {
    page_no: 1,
    results_per_page: 10
  },
  checkDate: null
};

export const RESERVE_EVENT = "RESERVE_EVENT";
export const RESERVE_EVENT_DONE = "RESERVE_EVENT_DONE";
export const RESERVE_EVENT_ERROR = "RESERVE_EVENT_ERROR";

export const TICKET_NB_SELECT = "TICKET_NB_SELECT";
export const EVENT_SELECT = "EVENT_SELECT";
export const EVENT_FETCH = "EVENT_FETCH";
export const EVENT_FETCH_DONE = "EVENT_FETCH_DONE";

export const RESERVED_SEAT_BY_EVENT = "RESERVED_SEAT_BY_EVENT";
export const RESERVED_SEAT_BY_EVENT_DONE = "RESERVED_SEAT_BY_EVENT_DONE";
export const RESERVED_SEAT_BY_EVENT_UPDATE = "RESERVED_SEAT_BY_EVENT_UPDATE";

export const RESERVED_SEATS = "RESERVED_SEATS";
export const RESERVED_SEATS_DONE = "RESERVED_SEATS_DONE";

export const HISTORY_SET_PAGING = "HISTORY_SET_PAGING";

const scheduleReducer = (state = initState, action) => {
  switch (action.type) {
    case RESERVE_EVENT:
      return {
        ...state,
        loading: true,
        error: null
      };
    case RESERVE_EVENT_DONE:
      return {
        ...state,
        loading: false,
        error: null
      };
    case RESERVE_EVENT_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case TICKET_NB_SELECT:
      return {
        ...state,
        loading: false,
        error: null,
        nbTicket: action.nbTicket
      };
    case EVENT_SELECT:
      return {
        ...state,
        loading: false,
        error: null,
        schedule: action.schedule
      };
    case EVENT_FETCH:
      return {
        ...state,
        loading: true,
        error: null
      };
    case EVENT_FETCH_DONE:
      return {
        ...state,
        loading: false,
        error: null,
        data: action.schedule
      };
    case RESERVED_SEAT_BY_EVENT:
      return {
        ...state,
        loading: true,
        error: null,
        seats: []
      };
    case RESERVED_SEAT_BY_EVENT_DONE:
      return {
        ...state,
        loading: false,
        error: null,
        seats: action.seats,
        checkDate: action.time
      };
    case RESERVED_SEAT_BY_EVENT_UPDATE:
      if (action.seats[0].Hls_Url === state.seats[0].Hls_Url) {
        return {
          ...state,
          loading: false,
          error: null,
          checkDate: action.time
        };
      } else {
        return {
          ...state,
          loading: false,
          error: null,
          seats: action.seats,
          checkDate: action.time
        };
      }
    case RESERVED_SEATS:
      return {
        ...state,
        loading: true,
        error: null
      };
    case RESERVED_SEATS_DONE:
      return {
        ...state,
        loading: false,
        error: null,
        allReservedSeats: action.allReservedSeats,
        historyPagingCount: action.paging.count
      };
    case HISTORY_SET_PAGING:
      return { ...state, historyPaging: action.paging };
    default:
      return state;
  }
};

export default scheduleReducer;
