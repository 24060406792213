import React from "react";
import Html from "./Html";
import Text from "./Text";
import Helmet from "react-helmet";
import { useTranslation } from "react-i18next";

const Mail = () => {
  const { t } = useTranslation();
  return (
    <div>
      <Helmet>
        <title>{t("registered_waiting_list_mail_subject")}</title>
        <meta name="mail-from-name" content="Echelle" />
        <meta name="mail-from" content="info@echelle.co.jp" />
      </Helmet>
      <Html />
      <Text />
    </div>
  );
};

export default Mail;
